import React, { useState, useEffect } from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    makeStyles,
    TableBody,
    Typography,
    TableFooter,
    TablePagination,
    TableSortLabel,
    CircularProgress,
    Checkbox,
} from '@material-ui/core';
import OverflowTip from './OverflowTip';
import { changeStateByNestedKey } from '@actions/fugaActions';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    header: {
        '& th': {
            borderRight: 'none',
        },
    },
    bodyRow: {
        '&:hover > *': {
            cursor: 'pointer',
            backgroundColor: '#F4F4F6',
        },
        '& td': {
            border: 'none',
            backgroundColor: '#FFFFFF',
        },
    },
    bodyCell: {
        maxWidth: '180px',
        minWidth: '30px',
    },
    bodyCellforSubModals: {
        maxWidth: '129px',
        minWidth: '30px',
    },
    checkboxRow: {
        padding: '0px',
    },
    selectedCount: {
        border: 'none',
        '& p': {
            width: '130px',
        },
    },
    footerAdditionContainer: {
        border: 'none',
        '& > *': {
            minWidth: '235px',
        },
    },
    tableSortIcon: {
        width: '100%',
    },
    flexHorizontal_s_b: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headerWithAdditionalContent: {
        display: 'flex',
    },
    wideTableCell: {
        maxWidth: '200px',
    },
    tableWrapper: {
        overflow: 'auto',
        width: '100%',
    },
    tableBody: {
        position: 'relative',
        width: '100%',
    },
    noData: {
        textAlign: 'center',
        height: '90px',
    },
    noDataText: {
        textAlign: 'center',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(243,243,243,0.5)',
        zIndex: 2,
    },
}));

const CellsForfullTable = React.memo(({ headerList }) => {
    if (headerList.length === 1)
        return (
            <>
                <TableCell></TableCell> <TableCell></TableCell>
            </>
        );
    if (headerList.length < 3) return <TableCell></TableCell>;
});

const updateList = (dataArray, value, selectedIndex) => {
    let newSelected = [];

    if (selectedIndex === -1) {
        newSelected = newSelected.concat(dataArray, value);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(dataArray.slice(1));
    } else if (selectedIndex === dataArray.length - 1) {
        newSelected = newSelected.concat(dataArray.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            dataArray.slice(0, selectedIndex),
            dataArray.slice(selectedIndex + 1),
        );
    }
    return newSelected;
};

const findDataIndex = (attribute, dataArray, item, fullRow) => {
    if (fullRow) {
        if (Array.isArray(attribute)) {
            //multi attributes check
            return dataArray.findIndex((dataItem) => {
                return attribute.every(
                    (attributeName) => dataItem[attributeName] === item[attributeName],
                );
            });
        } else {
            return dataArray.findIndex((dataItem) => dataItem[attribute] === item[attribute]);
        }
    } else {
        return dataArray.indexOf(item[attribute]);
    }
};

const AssetRow = React.memo((props) => {
    const {
        headerList,
        dataItem,
        cellDataCb,
        selected,
        setSelected,
        selection,
        excluded,
        setExcluded,
        downloadAllCmoAssets,
        onRowClick,
        storeName,
        isInSubModal,
    } = props;

    const classes = useStyles();

    const getDataForCell = (headerItem) => {
        if (cellDataCb) {
            return cellDataCb(headerItem, dataItem);
        } else {
            return (
                <Typography variant="body2">
                    {
                        <OverflowTip>
                            {dataItem[headerItem.dataIndex] ||
                                (dataItem[headerItem.dataIndex] === 0 ? '0' : '')}
                        </OverflowTip>
                    }
                </Typography>
            );
        }
    };

    const getValue = (value) => {
        if (selection.fullRowData) {
            return value;
        } else {
            const att = selection.filter;
            return value[att];
        }
    };

    const handleCheckBoxClick = (e, value) => {
        const indexExcluded = findDataIndex(
            selection.filter,
            excluded,
            dataItem,
            selection.fullRowData,
        );

        const indexSelected = findDataIndex(
            selection.filter,
            selected,
            dataItem,
            selection.fullRowData,
        );

        if (e.target.checked) {
            // remove from excluded list
            if (indexExcluded !== -1) {
                let newExcluded = updateList(excluded, getValue(value), indexExcluded);
                setExcluded(newExcluded);
            }
            // add in selected list
            if (indexSelected === -1) {
                let newSelected = updateList(selected, getValue(value), indexSelected);
                setSelected(newSelected);
            }
        }

        if (!e.target.checked) {
            // remove from selected list
            if (indexSelected !== -1) {
                let newSelected = updateList(selected, getValue(value), indexSelected);
                setSelected(newSelected);
            }
            // add in excluded list
            if (indexExcluded === -1) {
                let newExcluded = updateList(excluded, getValue(value), indexExcluded);
                setExcluded(newExcluded);
            }
        }

        selection.dispatchFn(
            selection.callbackFn(storeName, 'downloadAllCmoAssets', {
                requestFlag: downloadAllCmoAssets.requestFlag,
                localSelectAll: false,
            }),
        );
    };

    const isSelected = () => {
        return findDataIndex(selection.filter, selected, dataItem, selection.fullRowData) !== -1;
    };

    const handleRowClick = () => {
        if (onRowClick?.onRowClickCb)
            onRowClick.onRowClickCb(dataItem[onRowClick.rowDataField], dataItem);
    };

    const RowCheckbox = () => (
        <TableCell>
            {' '}
            <Checkbox
                className={classes.checkboxRow}
                color="primary"
                checked={isSelected()}
                // TODO disable checkboxes if the row has alert
                // disabled={!dataItem[selection.isValidField]}
                onClick={(event) => {
                    //For export v2 endpoints all row should be passed through instead a filter
                    handleCheckBoxClick(event, dataItem);
                }}
            />
        </TableCell>
    );
    const checkFixedWidth = (header) => {
        let widthConf = {};
        if (header.fixedWidth?.isFixed) widthConf.width = header.fixedWidth?.value;
        if (header.customStyle) widthConf = { ...header.customStyle };
        return widthConf;
    };

    return (
        <TableRow className={classes.bodyRow} onClick={handleRowClick}>
            {selection.isSelectable && <RowCheckbox />}
            {headerList.map((header, ind) => {
                return (
                    <TableCell
                        className={classNames({
                            [classes.bodyCellforSubModals]: isInSubModal,
                            [classes.bodyCell]: !isInSubModal && !header.wideTableCell,
                            [header.className]: header.className,
                        })}
                        style={checkFixedWidth(header)}
                        align={
                            header.hasNumericData && !header.numericHeaderOnly
                                ? 'right'
                                : header.hasIcon
                                  ? 'center'
                                  : 'left'
                        }
                        key={`${ind}-${header.dataIndex}`}
                    >
                        {getDataForCell(header)}
                    </TableCell>
                );
            })}
            {/* This is needed for MUI to fill the gap for row and header till the end of free space
          Same for line 402
      */}
            <CellsForfullTable headerList={headerList} />
        </TableRow>
    );
});

const useStylesSortIcon = makeStyles({
    icon: {
        margin: (props) => `${props.hasNumericData ? '0 8px 0 0' : '0 0 0 8px'}`,

        '& .MuiTableSortLabel-icon': {
            margin: (props) => `${props.hasNumericData ? '0 8px 0 0' : '0 0 0 8px'}`,
        },
    },
});

const CustomTableSortLabel = ({ hasNumericData, ...props }) => {
    const classes = useStylesSortIcon({ hasNumericData });
    return (
        <TableSortLabel {...props} classes={{ icon: classes.icon }}>
            {props.children}
        </TableSortLabel>
    );
};

const handleCallbackFn = (preferences, callbackFn, callBackParams, dispatchFn) => {
    if (preferences?.loadDataByPageCb?.noDispatch) {
        callbackFn(...callBackParams);
    } else {
        dispatchFn(callbackFn(...callBackParams));
    }
};

export default function CommonTableGrid({
    headerList,
    dataList, // necessarily should contain 'content' property as main array for iterations
    // dataList should contain following properties if paginationBE is true {content, totalElements, pageable: {pageNumber, pageSize}
    cellDataCb, // optional param, in case need to wrap cell into some component/tag
    loading,
    preferences, // mandatory: if paginationBE is true
    paginationBE,
    searchTerm,
    selection, // provide object with dispatch and callback fns if checkbox selection is available
    rowsPerPageOff,
    tablePaginationOff,
    initialRowsPerPage,
    sortPrefs, // for BE sorting on all pages
    footerAddition,
    isInSubModal,
    trackPagination,
}) {
    const {
        loadDataByPageCb: { argumentsList, dispatchFn, callbackFn },
        onRowClick,
    } = preferences;
    // mandatory: selected/setSelected should be created by UseRef hook
    const {
        selectedRows: selected = [],
        setSelectedRows: setSelected,
        excludedRows: excluded = [],
        setExcludedAssets: setExcluded,
        downloadAllCmoAssets: downloadAllCmoAssets = {},
        storeName,
    } = selection;
    const classes = useStyles();
    const [page, setPage] = useState(dataList?.pageable?.pageNumber || 0);
    const [rowsPerPage, setRowsPerPage] = useState(
        initialRowsPerPage || dataList?.pageable?.pageSize || 10,
    );
    const [orderBy, setOrderBy] = useState('trackTitle');
    const [order, setOrder] = useState('asc');
    const [data, setData] = useState([]);
    const [isHeaderCheckBoxDisabled, setIsHeaderCheckBoxDisabled] = useState(false);
    const makeLocalPagination = () => {
        if (!paginationBE && !tablePaginationOff) {
            const pagedArray = (dataList.content || []).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            );
            setData(pagedArray);
        }
    };

    useEffect(() => {
        setRowsPerPage(dataList?.pageable?.pageSize || 10);
        if (dataList.content) {
            setData(dataList.content);
            if (selection.isSelectable && dataList.content?.length === 0) {
                setSelected([]);
                setIsHeaderCheckBoxDisabled(true);
            }
            if (downloadAllCmoAssets?.requestFlag || downloadAllCmoAssets?.localSelectAll) {
                addToSelectedIfMissing(dataList.content);
            }
            // This if block makes local pagination work if it is not provided server side (from BE)
            makeLocalPagination();
        } else if (!('content' in dataList) && selection.isSelectable) {
            setSelected([]);
            setIsHeaderCheckBoxDisabled(true);
        }
    }, [dataList.content]);

    useEffect(() => {
        makeLocalPagination();
    }, [page, rowsPerPage]);

    useEffect(() => {
        setIsHeaderCheckBoxDisabled(false);
    }, [dataList.content]);

    useEffect(() => {
        setPage(dataList?.pageable?.pageNumber || 0);
    }, [dataList?.pageable?.pageNumber]);

    const addToSelectedIfMissing = (dataToSearch) => {
        let newSelected = [];
        dataToSearch.map((dataItem) => {
            const selectedIndex = findDataIndex(
                selection.filter,
                selected,
                dataItem,
                selection.fullRowData,
            );

            const excludedIndex = findDataIndex(
                selection.filter,
                excluded,
                dataItem,
                selection.fullRowData,
            );

            if (selectedIndex === -1 && excludedIndex === -1) {
                if (selection.fullRowData) {
                    newSelected.push(dataItem);
                } else {
                    newSelected.push(dataItem[selection.filter]);
                }
            }
        });
        setSelected(selected.concat(newSelected)); // works because of ref
    };

    const handleChangePage = (event, newPage) => {
        if (paginationBE) {
            if (downloadAllCmoAssets?.requestFlag || downloadAllCmoAssets?.localSelectAll) {
                addToSelectedIfMissing(dataList.content);
            }
        }
        setPage(newPage);
        if (trackPagination?.isPaginationTrackingOn) {
            // pageHasChanged param is mandatory for state that will be passed here
            trackPagination.paginationTrackingCB({
                pageHasChanged: true,
                page: newPage,
                size: rowsPerPage,
            });
        }
        if (paginationBE) {
            const callBackParams = [
                ...argumentsList,
                {
                    page: newPage,
                    size: rowsPerPage,
                    ...(searchTerm && { searchTerm: searchTerm }),
                    ...(sortPrefs?.value.value && { sort: sortPrefs?.value.value }),
                    ...(sortPrefs?.value.direction &&
                        !sortPrefs?.sortOnly && {
                            direction: sortPrefs?.value.direction,
                        }),
                },
            ];
            handleCallbackFn(preferences, callbackFn, callBackParams, dispatchFn);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        // works only when change page and come back (for local not for BE pagination)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        if (paginationBE) {
            const callBackParams = [
                ...argumentsList,
                {
                    page: 0,
                    size: event.target.value,
                    ...(searchTerm && { searchTerm: searchTerm }),
                    ...(sortPrefs?.value.value && { sort: sortPrefs?.value.value }),
                    ...(sortPrefs?.value.direction &&
                        !sortPrefs?.sortOnly && {
                            direction: sortPrefs?.value.direction,
                        }),
                },
            ];
            handleCallbackFn(preferences, callbackFn, callBackParams, dispatchFn);
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy.toString() === property.toString() && order === 'asc';
        const orderLocal = isAsc ? 'desc' : 'asc';
        setOrder(orderLocal);
        setOrderBy(property);

        // replace hardcoded line with dynamic if case will come again in the future with other field name
        const getProperty = (property) => {
            if (property === 'artistList') {
                return 'primaryArtist';
            } else if (property === 'ownershipTerritories') {
                return 'ownershipTerritoryCodes';
            } else {
                return property;
            }
        };

        if (sortPrefs) {
            const propertyChecked = getProperty(property);
            const sortDesc = `${(orderLocal + '').toUpperCase()}`;
            let sortV = `${propertyChecked}`;
            if (!sortPrefs.sortOnly) {
                if (Array.isArray(propertyChecked)) {
                    sortV = propertyChecked[0] + '';
                }
            } else if (sortPrefs.sortOnly) {
                sortV += `,${sortDesc}`;
            }

            dispatchFn(
                changeStateByNestedKey(sortPrefs.storeName, sortPrefs.storeSubName, {
                    value: sortV,
                    ...(!sortPrefs?.sortOnly && { direction: sortDesc }),
                }),
            );

            const callBackParams = [
                ...argumentsList,
                {
                    page: page,
                    size: rowsPerPage,
                    ...(searchTerm && { searchTerm: searchTerm }),
                    sort: sortV,
                    ...(!sortPrefs?.sortOnly && { direction: sortDesc }),
                },
            ];

            handleCallbackFn(preferences, callbackFn, callBackParams, dispatchFn);
        }
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const getHeaderContent = (headerItem) => {
        if (headerItem.disableSorting) {
            return headerItem.title;
        } else {
            const dataIndex = Array.isArray(headerItem.dataIndex)
                ? headerItem.dataIndex.join(',')
                : headerItem.dataIndex;

            return (
                <CustomTableSortLabel
                    hasNumericData={headerItem.hasNumericData}
                    style={{
                        flexDirection: headerItem.hasNumericData ? 'row-reverse' : 'inherit',
                    }}
                    active={orderBy === dataIndex}
                    direction={orderBy === dataIndex ? order : 'asc'}
                    onClick={createSortHandler(dataIndex)}
                >
                    {headerItem.title}
                </CustomTableSortLabel>
            );
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            let newSelected;
            if (selection.fullRowData) {
                newSelected = [...data];
            } else {
                newSelected = data.map((n) => n[selection.filter]);
            }

            // TODO disabled checkboxes selection if the row has alert
            // const newSelecteds = data.filter((n) => {
            //   return n[selection.isValidField]
            // }).map((n) => n[selection.filter]);
            setSelected(newSelected);
            setExcluded([]);
            selection.dispatchFn(
                selection.callbackFn(storeName, 'downloadAllCmoAssets', {
                    requestFlag: true,
                    localSelectAll: true,
                }),
            );
        } else {
            setSelected([]);
            selection.dispatchFn(
                selection.callbackFn(storeName, 'downloadAllCmoAssets', {
                    requestFlag: false,
                    localSelectAll: false,
                }),
            );
        }
    };

    const getSelectedCount = () => {
        let count = 0;
        if (downloadAllCmoAssets.requestFlag) {
            if (excluded.length > 0) {
                count = dataList.totalElements - excluded.length;
            } else {
                count = dataList.totalElements;
            }
        } else {
            count = selected.length;
        }
        return count;
    };

    const listTableContetToRender = (dataArray = []) => {
        if (!sortPrefs) {
            const defined = dataArray.filter((el) => el[orderBy]);
            const notDefined = dataArray.filter((el) => !el[orderBy]);
            defined.sort(getComparator(order, orderBy));
            dataArray = [...defined, ...notDefined];
        }
        return dataArray.map((el, ind) => {
            return (
                <AssetRow
                    key={`${ind}-common-table-grid`}
                    dataItem={el}
                    headerList={headerList}
                    cellDataCb={cellDataCb}
                    selected={selected}
                    setSelected={setSelected}
                    selection={selection}
                    excluded={excluded}
                    setExcluded={setExcluded}
                    downloadAllCmoAssets={downloadAllCmoAssets}
                    onRowClick={onRowClick}
                    storeName={storeName}
                    isInSubModal={isInSubModal}
                />
            );
        });
    };

    const getRowsPerPageList = () => {
        const list = [10, 20, 50, 100];
        if (!list.includes(initialRowsPerPage) && initialRowsPerPage > 0)
            list.push(initialRowsPerPage);
        return list;
    };

    return (
        <div className={classes.tableWrapper}>
            <Table style={{ position: 'relative', width: '100%' }}>
                <TableHead className={classes.header}>
                    <TableRow>
                        {selection.isSelectable && (
                            <TableCell>
                                <Checkbox
                                    className={classes.checkboxRow}
                                    color="primary"
                                    indeterminate={
                                        !downloadAllCmoAssets.localSelectAll &&
                                        selected.length > 0 &&
                                        selected.length !== dataList.totalElements
                                    }
                                    checked={
                                        (downloadAllCmoAssets.localSelectAll && data.length > 0) ||
                                        (data.length > 0 &&
                                            selected.length === dataList.totalElements)
                                    }
                                    onChange={handleSelectAllClick}
                                    disabled={isHeaderCheckBoxDisabled}
                                />
                            </TableCell>
                        )}
                        {headerList.map((header, ind) => {
                            return (
                                <TableCell
                                    align={header.hasNumericData ? 'right' : 'left'}
                                    key={`${header.dataIndex}-${ind}`}
                                    className={classNames({
                                        [header.className]: header.className,
                                    })}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        className={
                                            header?.additionalHeaderContent
                                                ? classes.headerWithAdditionalContent
                                                : ''
                                        }
                                    >
                                        {getHeaderContent(header)}
                                        {header?.additionalHeaderContent?.headerCallBack &&
                                            header.additionalHeaderContent.headerCallBack(header)}
                                    </Typography>
                                </TableCell>
                            );
                        })}
                        <CellsForfullTable headerList={headerList} />
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                    {loading && (
                        <div className={classes.loadingContainer}>
                            <CircularProgress />
                        </div>
                    )}
                    {data.length === 0 && (
                        <TableRow className={classes.bodyRow} style={{ height: '90px' }}>
                            <TableCell
                                colSpan={headerList.length + 1}
                                className={classes.noDataText}
                            >
                                No data
                            </TableCell>
                        </TableRow>
                    )}
                    {listTableContetToRender(data)}
                </TableBody>
                <TableFooter>
                    <>
                        {footerAddition && (
                            <TableCell className={classes.footerAdditionContainer}>
                                {footerAddition.itemComponent}
                            </TableCell>
                        )}
                        <TableCell className={classes.selectedCount}></TableCell>
                        <TableCell className={classes.selectedCount}>
                            {selection.isSelectable && (
                                <Typography variant="body1">{`Selected: (${getSelectedCount()})`}</Typography>
                            )}
                        </TableCell>
                        {!tablePaginationOff && (
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOff ? [] : getRowsPerPageList()}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                // labelRowsPerPage=''
                                // rowsPerPageOptions={[]}
                                // TODO commented rows above to disable rows per page
                                colSpan={10}
                                count={dataList.totalElements || dataList?.content?.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        )}
                    </>
                </TableFooter>
            </Table>
        </div>
    );
}
