import React, { useEffect, useState } from 'react';
import { Typography, Box, ButtonGroup, Button, Link, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClientDetailsContent from './ClientDetailsContent';
import ClientDealTermsContent from './ClientDealTermsContent';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ClientIdentifiersContent from './ClientIdentifiersContent';
import ClientBillingContent from './ClientBillingContent';
import ClientCommentsContent from './ClientCommentsContent';
import ClientContactsAndUsersContent from './ClientContactsAndUsersContent';
import { getRhClientInfoById } from '@actions/rightHolders';

const panes = {
    general: {
        label: 'General',
        id: 'general-client-info',
    },
    identifiers: {
        label: 'Identifiers',
        id: 'identifiers-client-info',
    },
    contactsAndUsers: {
        label: 'Contacts & Users',
        id: 'contacts-and-users',
    },
    dealTerms: {
        label: 'Deal Terms',
        id: 'deal_terms-client-info',
    },
    billing: {
        label: 'Billing',
        id: 'billing-client-info',
    },
    comments: {
        label: 'Comments',
        id: 'comments-client-info',
    },
};
const useStyles = makeStyles({
    root: {
        background: '#DEDEE0',
    },
});

function ClientMainDetailsNavigation() {
    const [active, setActive] = useState('General');
    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const areFieldsDisabled = useSelector(
        (state) => state.fugaReducers.clientPage.areFieldsDisabled,
    );

    useEffect(() => {
        if (params.id !== '-1') dispatch(getRhClientInfoById(params.id));
    }, [areFieldsDisabled]);

    const handleTabBtnClick = (tabName) => {
        setActive(tabName.label);
    };
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4}>
                <Grid item sx={2} md={3} lg={3} xl={2}>
                    <ButtonGroup orientation="vertical" style={{ width: '100%' }}>
                        {Object.keys(panes).map((tabName) => {
                            return (
                                <Button
                                    variant="text"
                                    key={panes[tabName].id}
                                    style={{ textTransform: 'unset', textAlign: 'left' }}
                                    onClick={() => handleTabBtnClick(panes[tabName])}
                                    className={`${
                                        active === panes[tabName].label ? classes.root : ''
                                    } tabBtn`}
                                >
                                    <Link underline="none" href={`#${panes[tabName].id}`}>
                                        <Typography variant="body1">
                                            {panes[tabName].label}
                                        </Typography>
                                    </Link>
                                </Button>
                            );
                        })}
                    </ButtonGroup>
                </Grid>
                <Grid item sx={10} md={9} lg={9} xl={10}>
                    <div style={{ padding: '32px 0 0' }}>
                        <General />
                        <Identifiers />
                        <ContactsAndUsers />
                        <DealTerms />
                        <Billing />
                        <Comments />
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}

function General() {
    return (
        <div id={panes.general.id}>
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                General
            </Typography>
            <ClientDetailsContent />
        </div>
    );
}

function Identifiers() {
    return (
        <div id={panes.identifiers.id}>
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Identifiers
            </Typography>
            <ClientIdentifiersContent />
        </div>
    );
}

function ContactsAndUsers() {
    return (
        <div id={panes.contactsAndUsers.id}>
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Contacts & Users
            </Typography>
            <ClientContactsAndUsersContent />
        </div>
    );
}

function DealTerms() {
    return (
        <div id={panes.dealTerms.id}>
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Deal Terms
            </Typography>
            <ClientDealTermsContent />
        </div>
    );
}

function Billing() {
    return (
        <div id={panes.billing.id}>
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Billing
            </Typography>
            <ClientBillingContent />
        </div>
    );
}

function Comments() {
    return (
        <div id={panes.comments.id}>
            <Typography variant="h6" style={{ fontWeight: 'bold', margin: '0 0 24px 0' }}>
                Comments
            </Typography>
            <ClientCommentsContent />
        </div>
    );
}

export default ClientMainDetailsNavigation;
