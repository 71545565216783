import React, { useState, useEffect } from 'react';
import {
    makeStyles,
    Popover,
    ClickAwayListener,
    Box,
    Typography,
    TextField,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    box: {
        width: '132px',
        minHeight: '147px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
    },
    itemsListContainer: {
        maxHeight: '463px',
        minHeight: '155px',
        width: '132px',
    },
    listItem: {
        padding: '13px 14px 14px 16px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            cursor: 'pointer',
        },
    },
    textfieldForFilter: {
        position: 'absolute',
        top: '-50px',
    },
}));

function CommonSimpleSuggestionsMenuList({
    isOpen,
    closeCb,
    onConfirmCb,
    anchorEl,
    menuItems = [], // structure shold be [{ nameToBeSent: '...', displayName: '...'}, { nameToBeSent: ....]
    setAnchorCb,
}) {
    const classes = useStyles();
    const [open, setOpen] = useState(isOpen);
    const [filterText, setFilterText] = useState('');

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
        closeCb(false);
        setAnchorCb(null);
        setFilterText('');
    };

    const handleItemClick = (value) => {
        onConfirmCb(value);
        handleClose();
    };

    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
    };

    const filteredMenuItems = menuItems
        .filter((el) => el.displayName.toLowerCase().startsWith(filterText.toLowerCase()))
        .sort((a, b) => a.displayName.localeCompare(b.displayName));
    return (
        <div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                style={{ marginTop: '5px' }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <Box className={`${classes.box}`}>
                        <TextField
                            label="Filter"
                            value={filterText}
                            onChange={handleFilterChange}
                            className={classes.textfieldForFilter}
                            autoFocus={true}
                        />
                        <div className={classes.itemsListContainer}>
                            {filteredMenuItems.map((el) => (
                                <div
                                    className={classes.listItem}
                                    onClick={() => handleItemClick(el)}
                                    key={el.nameToBeSent}
                                >
                                    <Typography variant="body1">{el.displayName}</Typography>
                                </div>
                            ))}
                        </div>
                    </Box>
                </ClickAwayListener>
            </Popover>
        </div>
    );
}

export default CommonSimpleSuggestionsMenuList;
