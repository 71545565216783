import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { Alert } from '@material-ui/lab';
import { getContribution, validateContributions } from '@actions/apiActions';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { ArrowDropDown, DeleteOutlined } from '@material-ui/icons';
import CommonLookupPopupWithSuggestionList from '@common/CommonLookupPopupWithSuggestionList';
import CommonTableGrid from '@common/CommonTableGrid';
import CommonSimpleSuggestionsMenuList from '@common/CommonSimpleSuggestionsMenuList';
import OverflowTip from '@common/OverflowTip';
import CommonConfirmationDialog from '@common/CommonConfirmationDialog';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
    },
    collapsed: {
        transform: 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expanded: {
        transform: 'rotate(0deg)',
    },
    listItem: {
        borderBottom: '1px solid #DEDEE0',
    },
    errorBanner: {
        backgroundColor: '#FFEBEE',
        marginBottom: '32px',
        '& .MuiAlert-message': {
            textAlign: 'left',
        },
    },
    warningBanner: {
        backgroundColor: '#FFF3E0',
        color: '#EA4B04',
        border: '1px solid #FFB44C',
        marginBottom: '32px',
        '& .MuiAlert-message': {
            textAlign: 'left',
        },
    },
    categorySection: {
        marginTop: '16px',
    },
    submodalSection: {
        marginTop: '32px',
    },
    iconBtn: {
        padding: '0',
        margin: '0',
        '&:hover': {
            background: 'none',
        },
    },
    inputsRow: {
        display: 'flex',
        alignItems: 'end',
        marginBottom: '32px',
        '& > *': {
            marginRight: '32px',
        },
    },
    actionBtn: {
        '&:hover': {
            cursor: 'pointer',
        },
        width: '18px',
        height: '18px',
        color: '#5F5F60',
    },
}));

const headers = [
    {
        title: 'Instrument/Role',
        dataIndex: 'instrumentsAndRoles',
    },
    {
        title: '',
        dataIndex: '',
        hasIcon: true,
    },
];

const validationSchema = Yup.object().shape({
    fullName: Yup.object().shape({
        nameToBeSent: Yup.string().required('Full name is required'),
    }),
    firstName: Yup.string().required('First name is required'),
    surname: Yup.string().required('Surname is required'),
    ipn: Yup.string().nullable(),
    dob: Yup.date().required('Date of birth is required'),
    nationality: Yup.object().shape({
        nameToBeSent: Yup.string().required('Nationality is required'),
    }),
    nrpId: Yup.number().nullable(),
    performanceCountry: Yup.object().shape({
        nameToBeSent: Yup.string().required('Country of performance is required'),
    }),
    evidence: Yup.string().nullable(),
    category: Yup.string().required('Category is required'),
    instrumentRoles: Yup.array().min(1, 'At least one instrument or role is required'),
});

const defaultValues = {
    fullName: {
        nameToBeSent: '',
        displayName: '',
        nameToBeSentReadonly: '',
    },
    firstName: '',
    surname: '',
    ipn: null,
    dob: null,
    nationality: '',
    category: 'CONTRACTED_FEATURED',
    nrpId: null,
    performanceCountry: '',
    evidence: '',
    instrumentRoles: [],
    previousPerformerId: null,
    currentPerformerId: null,
};

const NewContributorModal = ({ showModal, setShowModal, isEditing, setIsEditing, customData }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {
        handleSubmit,
        control,
        reset,
        getValues,
        setValue,
        trigger,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(validationSchema),
        reValidateMode: 'onChange',
        defaultValues,
    });

    const [isClient, setIsClient] = useState(false);
    const [isReselectableClient, setIsReselectableClient] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [nationalityAnchorEl, setNationalityAnchorEl] = useState(null);
    const [isNationalityLookupOpen, setIsNationalityLookupOpen] = useState(false);
    const [performanceCountryAnchorEl, setPerformanceCountryAnchorEl] = useState(null);
    const [isPerformanceCountryLookupOpen, setIsPerformanceCountryLookupOpen] = useState(false);
    const [rhCreationError, setRhCreationError] = useState('');
    const [isRoleAndInstrumenModalOpen, setIsRoleAndInstrumenModalOpen] = useState(false);
    const [roleAndInstrumenModalAnchorEl, setRoleAndInstrumenModalAnchorEl] = useState(null);
    const [isFullNameModalOpen, setIsFullNameModalOpen] = useState(false);
    const [fullNameModalAnchorEl, setFullNameModalAnchorEl] = useState(null);
    const [selectedInstrumentAndRoleDataItem, setSelectedInstrumentAndRoleDataItem] =
        useState(null); // null and not empty obj in order to make differentiate edit/noedit mode
    const territoriesModel = useSelector((state) => state.fugaReducers.territories);
    const assetMetadataModel = useSelector((state) => state.fugaReducers.assetMetadata);
    const {
        contributionValidations: contributionValidationsModel,
        allRolesAndInstrumentsFromBE,
        contributorDetailsObjFromBE,
        allPerformerNamesFromBE,
    } = assetMetadataModel;
    const { groupedTerritories } = territoriesModel;
    const mappedRolesAndInstrumentsList = allRolesAndInstrumentsFromBE.map((el) => ({
        nameToBeSent: el,
        displayName: el,
    }));

    const mappedPerformersNamesList = allPerformerNamesFromBE.map((el) => ({
        nameToBeSent: el.fullName,
        displayName: `${el.fullName} (${el.isClient ? 'Client' : 'Non-client'})`,
        subName: `${el.isClient ? 'Client' : 'Non-client'}`,
        nameToBeSentReadonly: el.fullName,
        dataItemFromBE: el,
    }));

    const mappedTerritoriesForSimpleMenuList = useMemo(() => {
        return groupedTerritories.reduce((acc, regionGroup) => {
            regionGroup.territories.forEach((countryObj) => {
                acc.push({
                    nameToBeSent: countryObj.code,
                    displayName: countryObj.name,
                });
            });
            return acc;
        }, []);
    }, [groupedTerritories]);

    const getFullNameValueObj = (value, isClient) => {
        return {
            nameToBeSent: value,
            displayName: value ? `${value} (${isClient ? 'Client' : 'Non-client'})` : '',
            subName: value ? `${isClient ? 'Client' : 'Non-client'}` : '',
            nameToBeSentReadonly: value,
            performerId: value.performerId,
        };
    };

    const getCountryDisplayName = (shortValue) => {
        return (
            mappedTerritoriesForSimpleMenuList.find(
                (country) => country.nameToBeSent === shortValue,
            )?.displayName || ''
        );
    };

    const firstName = useWatch({
        control,
        name: 'firstName',
    });

    const surname = useWatch({
        control,
        name: 'surname',
    });

    useEffect(() => {
        if (Object.keys(contributorDetailsObjFromBE).length > 0) {
            setValue(
                'fullName',
                getFullNameValueObj(
                    contributorDetailsObjFromBE.fullName,
                    contributorDetailsObjFromBE.client,
                ),
            );
            setValue('firstName', contributorDetailsObjFromBE.firstName || '');
            setValue('surname', contributorDetailsObjFromBE.lastName || '');
            setValue('ipn', contributorDetailsObjFromBE.ipn || '');
            setValue(
                'dob',
                contributorDetailsObjFromBE.dob ? new Date(contributorDetailsObjFromBE.dob) : null,
            );
            setValue('nationality', {
                displayName: getCountryDisplayName(contributorDetailsObjFromBE.nationality),
                nameToBeSent: contributorDetailsObjFromBE.nationality || '',
            });
            setValue('category', contributorDetailsObjFromBE?.category || 'CONTRACTED_FEATURED');
            setValue('nrpId', contributorDetailsObjFromBE.nrpId || null);
            setValue('performanceCountry', {
                displayName: getCountryDisplayName(contributorDetailsObjFromBE.performanceCountry),
                nameToBeSent: contributorDetailsObjFromBE.performanceCountry || '',
            });
            setValue('evidence', contributorDetailsObjFromBE.evidence || '');
            setValue(
                'instrumentRoles',
                (contributorDetailsObjFromBE.instrumentRoles || []).map((el) => el.instrRole),
            );
            setValue('currentPerformerId', contributorDetailsObjFromBE.performerId || 0);
            setValue('previousPerformerId', contributorDetailsObjFromBE.performerId || 0);

            // Set additional flags for client and category if necessary
            setIsClient(contributorDetailsObjFromBE.client);
        }
    }, [contributorDetailsObjFromBE, isEditing, setValue, setIsClient]);

    useEffect(() => {
        if (isEditing) {
            dispatch(
                getContribution(
                    customData.selectedRowDataItem.performerId,
                    customData.selectedRowDataItem.assetId,
                ),
            );
        }
    }, [isEditing]);

    useEffect(() => {
        if (!isClient && !isEditing) {
            const fullNameInProgress = `${firstName} ${surname}`;
            setValue('fullName', {
                ...getValues('fullName'),
                nameToBeSent: fullNameInProgress.trim(),
            });
        }
    }, [firstName, surname, isClient, isEditing, setValue, getValues]);

    const handleInputChange = (event) => {
        const value = event.currentTarget.value;
        const name = event.target.name;
        setValue(name, value);
        trigger();
    };

    const handleClose = () => {
        dispatch(changeStateByNestedKey('assetMetadata', 'contributorDetailsObjFromBE', {}));
        if (isEditing) setIsEditing(false);
        setValue('dob', null);
        reset(defaultValues);
        trigger();
        setShowModal(false);
    };

    const closeAndReloadTable = () => {
        handleClose();
        if (customData.onTableReload) {
            dispatch(customData.onTableReload.reloadCb(customData.onTableReload.params));
        }
    };

    const onCreationFailCb = (errMsg) => {
        setRhCreationError(errMsg);
    };

    const handleSave = (data) => {
        if (!data.dob) {
            toast('Please Fill in all the fields', { type: 'error' });
        } else {
            const objToSave = {
                ...data,
                dob: format(data.dob, 'yyyy-MM-dd'),
                nrpId: Number(data.nrpId),
                nationality: data.nationality.nameToBeSent,
                performanceCountry: data.performanceCountry.nameToBeSent,
                fullName: data.fullName.nameToBeSent,
                ...(isEditing && {
                    previousPerformerId: customData.selectedRowDataItem.performerId,
                }),
            };
            if (isEditing) {
                dispatch(
                    validateContributions(
                        customData.selectedRowDataItem.assetId,
                        objToSave,
                        closeAndReloadTable,
                        onCreationFailCb,
                        true,
                    ),
                );
            } else {
                dispatch(
                    validateContributions(
                        customData.selectedRowDataItem.assetId,
                        objToSave,
                        closeAndReloadTable,
                        onCreationFailCb,
                    ),
                );
            }
        }
    };

    const handleFullNameLookupOpen = ({ currentTarget }) => {
        setFullNameModalAnchorEl(currentTarget);
        setIsFullNameModalOpen(true);
    };

    const handleTerritoriesMenuListOpen = ({ currentTarget }) => {
        setNationalityAnchorEl(currentTarget);
        setIsNationalityLookupOpen(true);
    };

    const handlePerformanceCountryMenuListOpen = ({ currentTarget }) => {
        setPerformanceCountryAnchorEl(currentTarget);
        setIsPerformanceCountryLookupOpen(true);
    };

    const onRoleAndInstrumentLookupConfirm = (
        submittedElementFromLookup,
        { isEditing = false, selectedRowValue = '' },
    ) => {
        const rolesAndInstruments = getValues('instrumentRoles') || [];
        const selectedIndexGlobal = rolesAndInstruments.findIndex(
            (el) => el === submittedElementFromLookup.nameToBeSent,
        );

        if (isEditing) {
            const selectedIndex = rolesAndInstruments.findIndex(
                (el) => el === selectedRowValue.instrumentsAndRoles,
            );
            if (selectedIndex !== -1 && selectedIndexGlobal === -1) {
                rolesAndInstruments[selectedIndex] = submittedElementFromLookup.nameToBeSent;
            }
        } else {
            if (selectedIndexGlobal === -1) {
                rolesAndInstruments.push(submittedElementFromLookup.nameToBeSent);
            }
        }

        setValue('instrumentRoles', rolesAndInstruments);
        trigger('instrumentRoles');
    };

    const onFullNameLookupConfirm = (value) => {
        const dataItem = value.dataItemFromBE || {};
        const isClient = dataItem.isClient || false;

        if (isClient) {
            setIsReselectableClient(true);
            setIsClient(true);
        }

        value.nameToBeSentReadonly = value.nameToBeSent;
        setValue('fullName', value);
        setValue('firstName', dataItem.firstName || '');
        setValue('surname', dataItem.surName || '');
        setValue('ipn', dataItem.ipn || '');
        setValue('evidence', dataItem.evidence || '');
        setValue('nationality', {
            displayName: getCountryDisplayName(dataItem.nationality),
            nameToBeSent: dataItem.nationality || '',
        });
        setValue('dob', dataItem.dob ? new Date(dataItem.dob) : null);
        setValue('currentPerformerId', dataItem.performerId || 0);
        trigger();
    };

    const isAlertVisible = () => {
        const validationErrors =
            'valid' in contributionValidationsModel && !contributionValidationsModel.valid;
        return validationErrors || rhCreationError;
    };

    const isFullnameWarningVisible = () => {
        const fullNameValue = getValues('fullName')?.nameToBeSentReadonly || '';
        return fullNameValue.split(' ').length > 2 && !isClient;
    };

    const openRoleAndInstrumentModal = ({ currentTarget }) => {
        setIsRoleAndInstrumenModalOpen(true);
        setRoleAndInstrumenModalAnchorEl(currentTarget);
    };

    const handleNationalityChange = (value) => {
        handleInputChange({
            currentTarget: { value: value },
            target: { name: 'nationality' },
        });
    };

    const handlePerformanceCountryChange = (value) => {
        handleInputChange({
            currentTarget: { value: value },
            target: { name: 'performanceCountry' },
        });
    };

    const handleDeleteInstrumentOrRole = (selectedRowValue) => {
        const rolesAndInstruments = getValues('instrumentRoles') || [];
        const updatedRolesAndInstruments = rolesAndInstruments.filter(
            (el) => el !== selectedRowValue.instrumentsAndRoles,
        );
        setValue('instrumentRoles', updatedRolesAndInstruments);
        trigger();
    };

    const fieldsMapping = {
        fullName: 'Full name',
        firstName: 'First name',
        surname: 'Surname',
        ipn: 'IPN',
        dob: 'Date of birth',
        nationality: 'Nationality',
        category: 'Category',
        nrpId: 'NRP ID',
        performanceCountry: 'Country of performance',
        evidence: 'Evidence',
        instrumentRoles: 'Instruments or role',
        genericNames: 'General errors',
    };

    const getSpecificDataForInstrumentsAndRoles = useCallback((headerItem, dataItem) => {
        const coulumnTitle = headerItem.title.toLowerCase();
        if (coulumnTitle === '') {
            const handleEditClick = ({ currentTarget }) => {
                setSelectedInstrumentAndRoleDataItem(dataItem);
                setIsRoleAndInstrumenModalOpen(true);
                setRoleAndInstrumenModalAnchorEl(currentTarget);
            };
            const handleDeleteClick = () => {
                setSelectedInstrumentAndRoleDataItem(dataItem);
                setIsDeleteDialogOpen(true);
            };

            return (
                <div style={{ display: 'flex' }}>
                    <div onClick={handleEditClick}>
                        <ArrowDropDown
                            className={classes.actionBtn}
                            style={{ marginRight: '7px' }}
                        />
                    </div>
                    <div onClick={handleDeleteClick}>
                        <DeleteOutlined className={classes.actionBtn} />
                    </div>
                </div>
            );
        } else {
            return <OverflowTip>{dataItem[headerItem.dataIndex] || ''}</OverflowTip>;
        }
    });

    return (
        <>
            <Dialog onClose={handleClose} open={showModal} fullWidth maxWidth="sm">
                <DialogTitle onClose={handleClose}>
                    <Typography variant="subtitle1">Contributor details</Typography>
                </DialogTitle>
                <DialogContent>
                    {isAlertVisible() && (
                        <Alert className={classes.errorBanner} variant="outlined" severity="error">
                            {Object.entries(contributionValidationsModel.errors || []).map(
                                (err) => {
                                    const [errKey, errMsg] = err;
                                    return (
                                        <div key={errKey}>
                                            {`${fieldsMapping[errKey] || 'General'}: "${errMsg}"`}
                                            <br />
                                        </div>
                                    );
                                },
                            )}
                            <div>{rhCreationError}</div>
                        </Alert>
                    )}
                    {isFullnameWarningVisible() && (
                        <Alert
                            className={classes.warningBanner}
                            variant="outlined"
                            severity="warning"
                        >
                            <div>
                                This client has a middle name, please enter it manually in the first
                                name field if you'd like to save it.
                            </div>
                            <div>{`Initial full name is: ${
                                getValues()?.fullName?.nameToBeSentReadonly || ''
                            }`}</div>
                        </Alert>
                    )}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="fullName.displayName"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="Full name"
                                        type="text"
                                        fullWidth
                                        required
                                        error={!!errors.fullName}
                                        helperText={errors.fullName?.message}
                                        disabled={isClient && !isReselectableClient}
                                        InputProps={{
                                            readOnly: isClient && !isReselectableClient,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        className={classes.iconBtn}
                                                        disableRipple
                                                        onClick={(e) => handleFullNameLookupOpen(e)}
                                                    >
                                                        <ArrowDropDown />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Controller
                                name="firstName"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="First name"
                                        type="text"
                                        fullWidth
                                        required
                                        disabled={isClient}
                                        error={!!errors.firstName}
                                        helperText={errors.firstName?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="surname"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="Surname"
                                        type="text"
                                        fullWidth
                                        required
                                        error={!!errors.surname}
                                        helperText={errors.surname?.message}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Controller
                                name="ipn"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="IPN"
                                        type="text"
                                        fullWidth
                                        disabled={isClient}
                                        error={!!errors.ipn}
                                        helperText={errors.ipn?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name="dob"
                                control={control}
                                render={({ field }) => (
                                    <KeyboardDatePicker
                                        {...field}
                                        label="Date of birth"
                                        format="DD/MM/yyyy"
                                        fullWidth
                                        helperText={errors.dob?.message}
                                        error={!!errors.dob}
                                        onChange={(date) => {
                                            field.onChange(date);
                                            trigger('dob');
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name="nationality.displayName"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="Nationality"
                                        type="text"
                                        fullWidth
                                        required
                                        disabled={isClient}
                                        error={!!errors.nationality}
                                        helperText={errors.nationality?.message}
                                        InputProps={{
                                            readOnly: isClient,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        className={classes.iconBtn}
                                                        disableRipple
                                                        onClick={(e) =>
                                                            handleTerritoriesMenuListOpen(e)
                                                        }
                                                    >
                                                        <ArrowDropDown />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Controller
                                name="nrpId"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="NRP ID"
                                        type="text"
                                        fullWidth
                                        error={!!errors.nrpId}
                                        helperText={errors.nrpId?.message}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            trigger('nrpId');
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="performanceCountry.displayName"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="Country of performance"
                                        type="text"
                                        fullWidth
                                        required
                                        error={!!errors.performanceCountry}
                                        helperText={errors.performanceCountry?.message}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        className={classes.iconBtn}
                                                        disableRipple
                                                        onClick={(e) =>
                                                            handlePerformanceCountryMenuListOpen(e)
                                                        }
                                                    >
                                                        <ArrowDropDown />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Controller
                                name="evidence"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="Evidence"
                                        type="text"
                                        fullWidth
                                        error={!!errors.evidence}
                                        helperText={errors.evidence?.message}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="caption">Category (contract)</Typography>
                            <Controller
                                name="category"
                                control={control}
                                defaultValue="CONTRACTED_FEATURED"
                                rules={{ required: 'Category is required' }}
                                render={({ field }) => (
                                    <RadioGroup {...field} row style={{ marginTop: '4px' }}>
                                        <FormControlLabel
                                            value="CONTRACTED_FEATURED"
                                            control={<Radio size="small" color="primary" />}
                                            label={
                                                <Typography variant="body2">
                                                    Contracted Featured
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            value="NON_FEATURED"
                                            control={<Radio size="small" color="primary" />}
                                            label={
                                                <Typography variant="body2">
                                                    Non Featured
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            value="OTHER_FEATURED"
                                            control={<Radio size="small" color="primary" />}
                                            label={
                                                <Typography variant="body2">
                                                    Other Featured
                                                </Typography>
                                            }
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Instrument or role</Typography>
                            {/* Here you would include your table component with appropriate Grid layout */}
                            <CommonTableGrid
                                dataList={{
                                    content: (getValues('instrumentRoles') || []).map(
                                        (instrument) => ({
                                            instrumentsAndRoles: instrument,
                                        }),
                                    ),
                                }}
                                headerList={headers}
                                cellDataCb={getSpecificDataForInstrumentsAndRoles}
                                preferences={{
                                    loadDataByPageCb: {},
                                }}
                                selection={{
                                    isSelectable: false,
                                }}
                                isInSubModal={true}
                                rowsPerPageOff={true}
                                tablePaginationOff={true}
                                footerAddition={{
                                    itemComponent: (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            disableElevation
                                            disableRipple
                                            onClick={openRoleAndInstrumentModal}
                                        >
                                            Add Instrument or role
                                            <ArrowDropDown />
                                        </Button>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        disabled={!isValid}
                        variant="contained"
                        onClick={handleSubmit(handleSave)}
                        color="primary"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <CommonSimpleSuggestionsMenuList
                isOpen={isNationalityLookupOpen}
                closeCb={setIsNationalityLookupOpen}
                onConfirmCb={handleNationalityChange}
                anchorEl={nationalityAnchorEl}
                setAnchorCb={setNationalityAnchorEl}
                menuItems={mappedTerritoriesForSimpleMenuList}
            />
            <CommonSimpleSuggestionsMenuList
                isOpen={isPerformanceCountryLookupOpen}
                closeCb={setIsPerformanceCountryLookupOpen}
                onConfirmCb={handlePerformanceCountryChange}
                anchorEl={performanceCountryAnchorEl}
                setAnchorCb={setPerformanceCountryAnchorEl}
                menuItems={mappedTerritoriesForSimpleMenuList}
            />
            <CommonLookupPopupWithSuggestionList
                isOpen={isRoleAndInstrumenModalOpen}
                anchorEl={roleAndInstrumenModalAnchorEl}
                closeCb={setIsRoleAndInstrumenModalOpen}
                setAnchorCb={setRoleAndInstrumenModalAnchorEl}
                itemsList={mappedRolesAndInstrumentsList}
                onConfirmCb={onRoleAndInstrumentLookupConfirm}
                searchPlaceholder="Search instrument or role"
                displayOptions={{
                    noSubName: true,
                    noOptionBtns: true,
                    openUp: true,
                    initialSuggestionsOn: true,
                }}
                selectedItemOptions={{
                    selectedDataItem: selectedInstrumentAndRoleDataItem,
                    setSelectedDataItem: setSelectedInstrumentAndRoleDataItem,
                }}
            />
            <CommonLookupPopupWithSuggestionList
                isOpen={isFullNameModalOpen}
                anchorEl={fullNameModalAnchorEl}
                closeCb={setIsFullNameModalOpen}
                setAnchorCb={setFullNameModalAnchorEl}
                itemsList={mappedPerformersNamesList}
                onConfirmCb={onFullNameLookupConfirm}
                searchPlaceholder="Search performer"
            />
            <CommonConfirmationDialog
                isOpen={isDeleteDialogOpen}
                closeCb={setIsDeleteDialogOpen}
                title="Confirm delete Instrument or role"
                description={`Would you like to delete '${
                    selectedInstrumentAndRoleDataItem?.instrumentsAndRoles || ''
                }' ?`}
                onConfirmCb={handleDeleteInstrumentOrRole}
                confirmCbArgs={[selectedInstrumentAndRoleDataItem]}
                custumConfirmText="Delete"
            />
        </>
    );
};

export default NewContributorModal;
