import {
    Button,
    ClickAwayListener,
    Input,
    List,
    ListItem,
    ListSubheader,
    makeStyles,
    Paper,
    Popover,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '@styling/muiThemes/FugaMainStyles';
import React, { useEffect, useState } from 'react';
import { getArtistLookup } from '@api/assetController';
import { createArtistAPI } from '@api/metadataController';

const DEBOUNCE_DELAY = 300;
const sleep = (delay = 0) => new Promise((resolve) => setTimeout(resolve, delay));

const useStyles = makeStyles(() => ({
    listItem: {
        paddingTop: '20px',
        paddingBottom: '18px',
    },
}));

export function NewArtistModal({
    open,
    onClose,
    inputRef,
    anchorEl,
    loadArtistsData,
    assetId,
    productMode = false,
    artists,
    setArtists,
}) {
    const classes = useStyles();

    const [searchTerm, setSearchTerm] = useState('');
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (open && searchTerm.length > 2) {
            loadSuggestions(signal);
        }

        return () => {
            controller.abort();
        };
    }, [searchTerm]);

    const loadSuggestions = async (signal) => {
        await sleep(DEBOUNCE_DELAY);

        const newOptions = await getArtistLookup(searchTerm, signal);

        if (!signal.aborted) {
            setOptions(newOptions.data.artists || []);
        }
    };

    const addArtist = async (artistName) => {
        if (productMode) {
            const newArtist = {
                artistName,
                isPrimary: true,
                tempId: Date.now(),
            };

            setArtists([...artists, newArtist]);
            onClose();
            return;
        }

        await createArtistAPI(artistName, assetId);
        loadArtistsData();
        onClose();
    };

    const onSelectOption = async (index) => {
        const artistName = options[index];
        await addArtist(artistName);
    };

    const handleAddNew = async () => {
        await addArtist(searchTerm);
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <ClickAwayListener onClickAway={onClose}>
                <Paper style={{ padding: '8px 0 8px 16px' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '334px',
                            height: '383px',
                        }}
                    >
                        <Input
                            placeholder="Type to search ..."
                            inputProps={{ autoComplete: 'false' }}
                            inputRef={inputRef}
                            onChange={(evt) => setSearchTerm(evt.target.value)}
                            style={{ marginRight: '16px' }}
                        />
                        <List className="hideScroll sugesstions-list">
                            {options.length > 0 && (
                                <ListSubheader disableSticky> Suggested </ListSubheader>
                            )}
                            {options.map((item, index) => {
                                return (
                                    <ListItem
                                        className={classes.listItem}
                                        button
                                        key={item}
                                        value={item}
                                        onClick={() => onSelectOption(index)}
                                    >
                                        {item}
                                    </ListItem>
                                );
                            })}
                        </List>
                    </div>
                    <div className="flex-horizontal">
                        <ThemeProvider theme={theme}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleAddNew}
                                disableElevation
                            >
                                ADD AS NEW
                            </Button>
                            <Button color="primary" onClick={onClose} disableElevation>
                                CANCEL
                            </Button>
                        </ThemeProvider>
                    </div>
                </Paper>
            </ClickAwayListener>
        </Popover>
    );
}
