import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllPerformerNamesForMetadata,
    getAllRolesAndInstrumentsForMetadata,
    getAssetMetadataById,
} from '@actions/apiActions';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { changeStateByNestedKey } from '@actions/fugaActions';
import AssetMetaDataRightsHoldersSection from './AssetMetaDataRightsHoldersSection';
import AssetMetaDataContributorsSection from './AssetMetaDataContributorsSection';
import AssetMetaDataProductsSection from './AssetMetaDataProductsSection';
import { AssetMetaDataArtistsSection } from '@common/AssetMetaDataEdit/AssetMetaDataArtistsSection';
import { AssetMetaDataRecordingDetailsSection } from '@common/AssetMetaDataEdit/AssetMetaDataRecordingDetailsSection';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { assetValidationScheme } from '@utils/validations/assetValidation';
import { AssetMetaDataCopyrightSection } from '@common/AssetMetaDataEdit/AssetMetaDataCopyrightSection';
import { ASSET_METADATA_FIELDS } from '@utils/constants/AssetMetadataEditModal';

const useStyles = makeStyles(() => ({
    title: {
        padding: '32px 32px 16px 32px',
        marginBottom: '16px',
    },
    content: {
        padding: '32px',
    },
    textField: {
        paddingBottom: 0,
        '& .MuiInputBase-input': {
            fontSize: '14px',
            lineHeight: '18px',
        },
    },
    commentCounter: {
        display: 'flex',
        paddingBottom: '21px',
        justifyContent: 'flex-end',
    },
    inputsContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    inputItemContainer: {
        display: 'flex',
        alignItems: 'end',
        width: '100%',
        marginBottom: '16px',
    },
    firsInputInRow: {
        marginRight: '32px',
    },
    alertText: {
        '& .MuiAlert-message': {
            textAlign: 'left',
        },
    },
    section: {
        marginTop: '32px',
    },
    sectionTitle: {
        marginBottom: '16px',
    },
    dialogPaper: {
        '& .MuiDialog-paperWidthMd': {
            maxWidth: '964px',
        },
    },
}));

const defaultValues = {
    assetTrackTitle: '',
    assetTrackVersion: '',
    assetIsrc: '',
    assetDisplayArtists: '',
    assetFugaId: '',
    numberOfFeaturedContr: '',
    numberOfNonFeaturedContr: '',
    lineUpComplete: '',
    duration: '',
    contentType: '',
    recordingCountry: '',
    recordingDate: null,
    recordingYear: '',
    isRemastered: false,
    medley: false,
    containsSample: false,
    genre: '',
    language: '',
    pname: '',
    pyear: '',
};

export default function AssetMetaDataEditModal({
    isOpen,
    setOpen,
    dataItem,
    onSuccessCb,
    isPerformer,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [mainDetailsInptValues, setMainDetailsInptValues] = useState({});

    const assetMetadataModel = useSelector((state) => state.fugaReducers.assetMetadata);
    const {
        dataObjFromBE,
        rhOwnershipDataFromBE,
        rhOwnershipSaveResult,
        contributorsDataFromBE,
        contributionSaveResult,
        productsDataFromBE,
    } = assetMetadataModel;
    const artists = useSelector((state) => state.fugaReducers.assetMetadata.artistsDataFromBE);

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(assetValidationScheme),
        defaultValues,
    });
    const { reset, trigger } = methods;

    useEffect(() => {
        if (isOpen) {
            dispatch(getAssetMetadataById(dataItem.assetId));
            dispatch(changeStateByNestedKey('assetMetadata', 'validations', {}));
            dispatch(getAllRolesAndInstrumentsForMetadata());
            dispatch(getAllPerformerNamesForMetadata());
        }
    }, [isOpen]);

    useEffect(() => {
        if (dataObjFromBE.assetId) {
            reset({
                [ASSET_METADATA_FIELDS.assetTrackTitle]: dataObjFromBE?.assetTrackTitle,
                [ASSET_METADATA_FIELDS.assetTrackVersion]: dataObjFromBE?.assetTrackVersion,
                [ASSET_METADATA_FIELDS.assetIsrc]: dataObjFromBE?.assetIsrc,
                [ASSET_METADATA_FIELDS.assetFugaId]: dataObjFromBE?.assetFugaId,
                [ASSET_METADATA_FIELDS.assetNRPId]: dataObjFromBE?.assetNrpId,
                [ASSET_METADATA_FIELDS.assetDisplayArtists]: dataObjFromBE?.assetDisplayArtists,
                [ASSET_METADATA_FIELDS.numberOfFeaturedContr]: dataObjFromBE?.numberOfFeaturedContr,
                [ASSET_METADATA_FIELDS.numberOfNonFeaturedContr]:
                    dataObjFromBE?.numberOfNonFeaturedContr,
                [ASSET_METADATA_FIELDS.lineUpComplete]: dataObjFromBE?.lineUpComplete?.toString(),
                [ASSET_METADATA_FIELDS.pyear]: dataObjFromBE?.pyear,
                [ASSET_METADATA_FIELDS.pname]: dataObjFromBE?.pname,
                [ASSET_METADATA_FIELDS.duration]: dataObjFromBE?.duration,
                [ASSET_METADATA_FIELDS.contentType]: dataObjFromBE?.contentType,
                [ASSET_METADATA_FIELDS.recordingCountry]: dataObjFromBE?.recordingCountry,
                [ASSET_METADATA_FIELDS.commissioningCountry]: dataObjFromBE?.commissioningCountry,
                [ASSET_METADATA_FIELDS.recordingDate]: dataObjFromBE?.recordingDate,
                [ASSET_METADATA_FIELDS.recordingYear]: dataObjFromBE?.recordingYear,
                [ASSET_METADATA_FIELDS.isRemastered]: dataObjFromBE?.isRemastered,
                [ASSET_METADATA_FIELDS.medley]: dataObjFromBE?.medley,
                [ASSET_METADATA_FIELDS.containsSample]: dataObjFromBE?.containsSample,
                [ASSET_METADATA_FIELDS.genre]: dataObjFromBE?.genre?.toLowerCase(),
                [ASSET_METADATA_FIELDS.language]: dataObjFromBE?.language,
            });
            trigger();
        }
    }, [dataObjFromBE, trigger, reset]);

    const tableReloadOnSuccess = () => {
        return dispatch(getAssetMetadataById(dataItem.assetId));
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
            reset(defaultValues);
            dispatch(
                changeStateByNestedKey('assetMetadata', 'rhOwnershipDataFromBE', { content: [] }),
            );
            dispatch(
                changeStateByNestedKey('assetMetadata', 'contributorsDataFromBE', { content: [] }),
            );
            dispatch(
                changeStateByNestedKey('assetMetadata', 'rhOwnershipSaveResult', {
                    isSuccessful: false,
                    hasWarning: false,
                    isDeleted: false,
                }),
            );
            dispatch(
                changeStateByNestedKey('assetMetadata', 'contributionSaveResult', {
                    isSuccessful: false,
                    hasWarning: false,
                    isDeleted: false,
                }),
            );
            dispatch(changeStateByNestedKey('assetMetadata', 'dataObjFromBE', {}));
        }
    };

    const handleSave = () => {
        if (onSuccessCb) {
            const formValues = methods.getValues();

            dispatch(
                onSuccessCb(
                    dataItem.assetId,
                    formValues,
                    isPerformer,
                    {
                        isUpdateble: true,
                        arrayName: 'content',
                        idFieldName: 'assetId',
                        id: dataItem.assetId,
                        // update this list once new input field will be added to UI
                        // this field and also warningFieldNameMapping and fieldsMapping in editAssetMetadataById
                        // and reset obj with content in handleClose
                        fieldNamesListToChange: [],
                        isPerformer,
                    },
                    handleClose,
                ),
            );
        }
    };

    const castValueByitsType = (value, valueType) => {
        let finalValue = '';
        if (valueType.isNumber) {
            finalValue = parseInt(value);
        } else if (valueType.isBool) {
            const checkedValue = value === 'false' ? '' : value;
            finalValue = Boolean(checkedValue);
        } else {
            finalValue = value;
        }
        return finalValue;
    };

    const handleInputChange = (event, valueType = { isNumber: false, isBool: false }) => {
        const value = event.currentTarget.value;
        const name = event.target.name;
        const valueToSet = castValueByitsType(value, valueType);
        setMainDetailsInptValues({
            ...mainDetailsInptValues,
            [name]: valueToSet,
        });
    };

    return (
        <Dialog onClose={handleClose} open={isOpen} maxWidth="md" className={classes.dialogPaper}>
            <FormProvider {...methods}>
                <DialogTitle onClose={handleClose} className={`${classes.title}`}>
                    <Typography variant="h5">Asset: {dataItem.trackTitle}</Typography>
                </DialogTitle>
                <DialogContent className={`${classes.content}`}>
                    <Typography variant="h6" className={classes.sectionTitle}>
                        Main details
                    </Typography>
                    <div className={classes.inputsContainer}>
                        <div className={`${classes.inputItemContainer}`}>
                            <Controller
                                name={ASSET_METADATA_FIELDS.assetTrackTitle}
                                control={methods.control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="Track Title"
                                        required
                                        multiline
                                        maxRows={10}
                                        fullWidth
                                        error={!!methods.formState.errors.assetTrackTitle}
                                        helperText={
                                            methods.formState.errors.assetTrackTitle?.message
                                        }
                                        classNameOpt={`${classes.textField} ${classes.firsInputInRow}`}
                                        style={{ minWidth: '622px' }}
                                    />
                                )}
                            />
                            <Controller
                                name={ASSET_METADATA_FIELDS.assetTrackVersion}
                                control={methods.control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="Track Version"
                                        multiline
                                        maxRows={10}
                                        fullWidth
                                        required
                                        error={!!methods.formState.errors.assetTrackVersion}
                                        helperText={
                                            methods.formState.errors.assetTrackVersion?.message
                                        }
                                        classNameOpt={classes.textField}
                                        style={{ minWidth: '226px' }}
                                    />
                                )}
                            />
                        </div>
                        <div className={`${classes.inputItemContainer}`}>
                            <Controller
                                name={ASSET_METADATA_FIELDS.assetIsrc}
                                control={methods.control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="ISRC"
                                        required
                                        multiline
                                        maxRows={10}
                                        fullWidth
                                        error={!!methods.formState.errors.assetIsrc}
                                        helperText={methods.formState.errors.assetIsrc?.message}
                                        classNameOpt={`${classes.textField} ${classes.firsInputInRow}`}
                                        style={{ width: '121px' }}
                                    />
                                )}
                            />
                            <Controller
                                name={ASSET_METADATA_FIELDS.assetNRPId}
                                control={methods.control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="DNR ID"
                                        required
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        error={!!methods.formState.errors.assetNrpId}
                                        helperText={methods.formState.errors.assetNrpId?.message}
                                        classNameOpt={`${classes.textField} ${classes.firsInputInRow}`}
                                        style={{ width: '121px' }}
                                    />
                                )}
                            />
                            <Controller
                                name={ASSET_METADATA_FIELDS.assetFugaId}
                                control={methods.control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="FUGA ID"
                                        fullWidth
                                        required
                                        error={!!methods.formState.errors.assetFugaId}
                                        helperText={methods.formState.errors.assetFugaId?.message}
                                        classNameOpt={classes.textField}
                                        style={{ width: '121px' }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className={classes.section}>
                        <Typography variant="h6" className={classes.sectionTitle}>
                            Artists
                        </Typography>
                        <AssetMetaDataArtistsSection
                            dataItem={dataItem}
                            tableReloadOnSuccess={tableReloadOnSuccess}
                            artists={artists}
                            displayArtistsFieldName={ASSET_METADATA_FIELDS.assetDisplayArtists}
                        />
                    </div>
                    <div className={classes.section}>
                        <Typography variant="h6" className={classes.sectionTitle}>
                            Recording Details
                        </Typography>
                        <AssetMetaDataRecordingDetailsSection dataItem={dataObjFromBE} />
                    </div>
                    <div className={classes.section}>
                        <Typography variant="h6" className={classes.sectionTitle}>
                            Copyright
                        </Typography>
                        <AssetMetaDataCopyrightSection />
                    </div>
                    <div className={classes.section}>
                        <AssetMetaDataContributorsSection
                            dataItem={dataItem}
                            contributorsDataFromBE={contributorsDataFromBE}
                            inputOperations={{
                                handleInputChange,
                                mainDetailsInptValues,
                            }}
                            tableReloadOnSuccess={tableReloadOnSuccess}
                            contributionSaveResult={contributionSaveResult}
                        />
                    </div>
                    <div className={classes.section}>
                        <AssetMetaDataProductsSection
                            dataItem={dataItem}
                            productsDataFromBE={productsDataFromBE}
                            tableReloadOnSuccess={tableReloadOnSuccess}
                        />
                    </div>
                    <div className={classes.section}>
                        <AssetMetaDataRightsHoldersSection
                            dataItem={dataItem}
                            rhOwnershipDataFromBE={rhOwnershipDataFromBE}
                            rhOwnershipSaveResult={rhOwnershipSaveResult}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        color="primary"
                        disableElevation
                    >
                        cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        variant="contained"
                        color="primary"
                        disableElevation
                        disabled={!methods.formState.isValid || !methods.formState.isDirty}
                    >
                        Save
                    </Button>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
}
