import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    makeStyles,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { isSymbolsCountValid } from '@common/modals/common';
import { changeStateByKey } from '@actions/fugaActions';
import { createCommentByCmoRegIdAPI, getCommentsByCmoRegIdAPI } from '@api/commentController';

const useStyles = makeStyles(() => ({
    title: {
        padding: '19px 16px',
    },
    content: {
        padding: '10px 16px',
    },
    textField: {
        width: '552px',
        paddingBottom: 0,
    },
    commentCounter: {
        display: 'flex',
        paddingBottom: '21px',
        justifyContent: 'flex-end',
    },
}));

export default function CmoRegCommentsModal({ isOpen, setOpen, dataItem, onSuccessCb }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [commentInput, setCommentInput] = useState('');
    const cmoCommentsModel = useSelector((state) => state.fugaReducers.cmoComments);
    const [commentSymCount, setCommentSymCount] = useState(0);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    useEffect(() => {
        if (cmoCommentsModel) {
            isSymbolsCountValid(
                cmoCommentsModel,
                handleTooltipOpen,
                handleTooltipClose,
                setCommentSymCount,
            );
        } else {
            setCommentSymCount(0);
        }
        setCommentInput(cmoCommentsModel || '');
    }, [cmoCommentsModel]);

    const handleOpen = async () => {
        if (isOpen) {
            try {
                const resp = await getCommentsByCmoRegIdAPI(dataItem.cmoRegId);
                dispatch(changeStateByKey('cmoComments', resp.data.commentMsg || ''));
                setCommentInput(cmoCommentsModel || '');
            } catch (e) {
                dispatch(changeStateByKey('cmoComments', ''));
            }
        }
    };

    useEffect(() => {
        handleOpen();
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
        setCommentInput('');
    };

    const handleSave = async () => {
        await createCommentByCmoRegIdAPI({
            commentMsg: commentInput,
            clientCmoRegistrationId: dataItem.cmoRegId,
        });

        if (onSuccessCb) {
            onSuccessCb();
        }

        handleClose();
    };

    const handleInputChange = (e) => {
        const value = e.currentTarget.value;

        if (isSymbolsCountValid(value, handleTooltipOpen, handleTooltipClose, setCommentSymCount)) {
            setCommentInput(value);
        }
    };

    return (
        <Dialog onClose={handleClose} open={isOpen} maxWidth="md">
            <DialogTitle onClose={handleClose} className={`${classes.title}`}>
                <Typography variant="h6">Edit Comment</Typography>
            </DialogTitle>
            <DialogContent className={`${classes.content}`}>
                <Tooltip
                    title="Input text should be less than 5000 charactes"
                    disableHoverListener="true"
                    open={tooltipOpen}
                >
                    <TextField
                        label="Comment"
                        onChange={(evt) => handleInputChange(evt)}
                        name="comment"
                        type="text"
                        multiline
                        maxRows={10}
                        value={commentInput || ''}
                        className={classes.textField}
                        onBlur={() => setTooltipOpen(false)}
                    />
                </Tooltip>
                <div className={classes.commentCounter}>
                    <FormHelperText>{commentSymCount}/5000</FormHelperText>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary" disableElevation>
                    cancel
                </Button>
                <Button onClick={handleSave} variant="contained" color="primary" disableElevation>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
