import userData from '@data/userData';
import Fetch from '@utils/Fetch';
import { changeStateByKey, changeStateByNestedKey } from './fugaActions';

// Utils

const handleResponse = async (res, dispatch, stateType) => {
    const result = await res.json();
    const dataToSet = result.data.content ? result.data : { ...result.data, content: [] };

    if (res.ok) {
        dispatch(changeStateByNestedKey(stateType, 'dataFromBE', dataToSet));
    } else {
        dispatch(
            changeStateByKey('infoMessage', {
                isVisible: true,
                message: result.error,
                severity: 'error',
            }),
        );
    }
    dispatch(changeStateByNestedKey(stateType, 'tableIsLoading', false));
};

const fetchData = async (url, dispatch, stateType, options = {}) => {
    try {
        const res = await Fetch.fetchWrapper(url, options);
        await handleResponse(res, dispatch, stateType);
    } catch (error) {
        console.log('Failed to send request via url: ' + url, `error msg: ${error}`);
    }
};

const fetchAssets = (url, dispatch, stateType, options = {}) => {
    dispatch(changeStateByNestedKey(stateType, 'tableIsLoading', true));

    options.body = JSON.stringify(options.body);

    fetchData(url, dispatch, stateType, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        ...options,
    });
};

// API calls actions

export function getPerformerAssets(id, queryParams, bodyParams, signal) {
    return (dispatch) => {
        const url = new URL(`${userData.api.baseAssetUrl()}/performer/cmo/${id}`);
        url.search = new URLSearchParams(queryParams);
        fetchAssets(url, dispatch, 'performerCmoPage', { body: bodyParams, signal });
    };
}

export function getRightsHolderAssets(clientId, queryParams, bodyParams, signal) {
    return (dispatch) => {
        if (!queryParams.claimMethod) {
            queryParams.claimMethod = 'AGENT';
        }

        const url = new URL(`${userData.api.baseAssetUrl()}/rh/cmo/${clientId}`);
        url.search = new URLSearchParams(queryParams);

        fetchAssets(url, dispatch, 'cmoPage', { body: bodyParams, signal });
    };
}
