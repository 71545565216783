import * as Yup from 'yup';

const currentYear = new Date().getFullYear();

const assetValidationScheme = Yup.object().shape({
    // Main form fields
    assetTrackTitle: Yup.string()
        .required('Track title is required')
        .max(300, 'Track title must be at most 300 characters'),

    assetTrackVersion: Yup.string()
        .required('Track version is required')
        .max(300, 'Track version must be at most 300 characters'),

    assetIsrc: Yup.string()
        .required('ISRC is required')
        .matches(/^[A-Z]{2}[A-Z0-9]{3}\d{7}$/, 'Invalid ISRC format'),

    assetDisplayArtists: Yup.string()
        .required('Display artist(s) are required')
        .max(300, 'Display artist(s) must be at most 300 characters'),

    assetFugaId: Yup.number().required('FUGA ID is required').typeError('FUGA ID must be a number'),

    numberOfFeaturedContr: Yup.number()
        .transform((value, originalValue) => (originalValue === '' ? 0 : value))
        .typeError('Featured performers must be a number'),

    numberOfNonFeaturedContr: Yup.number()
        .transform((value, originalValue) => (originalValue === '' ? 0 : value))
        .typeError('Non-featured performers must be a number'),

    lineUpComplete: Yup.string().nullable(),

    // Recording Details Section
    duration: Yup.string()
        .required('Duration is required')
        .matches(/^\d+$/, 'Duration must be a numeric value')
        .test(
            'is-valid-duration',
            'Duration cannot be 0, use "unknown" if duration is unidentified',
            (value) => value !== '0',
        )
        .test(
            'is-valid-duration-range',
            'Warning: Duration is between 1-10 seconds, please verify',
            (value) => {
                const duration = parseInt(value, 10);
                return duration === 0 || duration > 10; // Warn for 1-10 seconds
            },
        ),

    contentType: Yup.string().required('Content type is required'),

    recordingCountry: Yup.string().required('Country of recording is required'),

    commissioningCountry: Yup.string().required('Commissioning country is required'),

    recordingDate: Yup.date()
        .nullable()
        .test(
            'is-valid-recording-date',
            'Recording date cannot be after the current year or before 1900',
            (value) => {
                if (!value) return true;
                const year = value.getFullYear();
                return year >= 1900 && year <= currentYear + 1;
            },
        )
        .test('is-before-pyear', 'Recording date cannot be after PYear', function (value) {
            const pyear = parseInt(this.resolve(Yup.ref('pyear')), 10);
            return !value || !pyear || value.getFullYear() <= pyear;
        })
        .test(
            'is-before-recordingYear',
            'Recording date cannot be after Recording Year',
            function (value) {
                const recordingYear = parseInt(this.resolve(Yup.ref('recordingYear')), 10);
                return !value || !recordingYear || value.getFullYear() <= recordingYear;
            },
        ),

    recordingYear: Yup.number()
        .required('Recording year is required')
        .min(1900, 'Recording year must be later than 1900')
        .max(currentYear, `Recording year must not be later than the current year (${currentYear})`)
        .test('is-before-pyear', 'Recording year cannot be after PYear', function (value) {
            const pyear = this.resolve(Yup.ref('pyear'));
            return !pyear || value <= pyear;
        }),

    isRemastered: Yup.boolean().nullable(),
    medley: Yup.boolean().nullable(),
    containsSample: Yup.boolean().nullable(),

    genre: Yup.string().required('Genre is required').notOneOf([''], 'Genre is required'),

    language: Yup.string().required('Language of performance is required'),

    pname: Yup.string()
        .required('PName is required')
        .max(300, 'PName must be at most 300 characters'),

    pyear: Yup.number()
        .required('PYear is required')
        .min(1900, 'PYear must be later than 1900')
        .max(currentYear + 1, `PYear must not be later than next year (${currentYear + 1})`)
        .test(
            'is-after-recordingYear',
            'PYear cannot be before the recording year',
            function (value) {
                const recordingYear = this.resolve(Yup.ref('recordingYear'));
                return value >= recordingYear;
            },
        ),
});

export { assetValidationScheme };
