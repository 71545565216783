import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    makeStyles,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import CommonDropDownButtonWithFixedLabel from '@common/CommonDropDownButtonWithFixedLabel.jsx';
import SaveIconSvg from '@images/svgComponents/SaveIconSvg';
import CrossIconSvg from '@images/svgComponents/CrossIconSvg';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper.jsx';
import {
    raiseBulkAlertForPerformer,
    raiseBulkAlertForRightsHolder,
} from '@api/assetsRegistrationBulkActions.jsx';
import { getFiltersToSend, manualAlertsItemsList } from '@pages/AssetsCmoPage/common/common';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import classNames from 'classnames';
import { isSymbolsCountValid } from '@common/modals/common';

const useStyles = makeStyles((theme) => ({
    title: {
        padding: '19px 16px',
    },
    content: {
        padding: '10px 16px',
        minWidth: '568px',
    },
    textField: {
        width: '488px',
        padding: 0,
    },
    textCounter: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '488px',
    },
    addAlertButton: {
        fontSize: '13px',
        height: '30px',
        padding: '4px 10px',
    },
    addIcon: {
        margin: '0 10px 0 0',
        width: '18px',
        height: '18px',
    },
    addAlertBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    alertDropDownLabel: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '18px',
        letterSpacing: '0.25px',
        textTransform: 'capitalize',
        padding: 0,
        '&:hover': {
            background: 'unset',
        },
    },
    operationsContainer: {
        display: 'flex',
        alignItems: 'center',
        minWidth: theme.spacing(9),
        justifyContent: 'space-between',
    },
    saveIcon: {
        marginLeft: '16px',
        marginRight: '4px',
    },
    thumbsUPIcon: {
        marginLeft: '16px',
        marginRight: '4px',
    },
    operationIcons: {
        '&:hover': { cursor: 'pointer' },
        marginLeft: '8px',
        display: 'flex',
        alignItems: 'center',
    },
    disabledIcon: {
        '& svg': { fill: '#00000061' },
        '&:hover': { cursor: 'unset' },
    },
    metaDataIcons: {
        marginRight: '8px',
        display: 'flex',
        alignItems: 'center',
    },
    actionNDateContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '18px',
        justifyContent: 'space-between',
    },
    editIcon: {
        '&:hover': {
            cursor: 'pointer',
        },
        width: '24px',
        height: '24px',
        color: '#5F5F60',
        marginLeft: '8px',
    },
    coloredIcon: {
        height: '18px',
    },
    alertRow: {
        marginBottom: '26px',
    },
    icon: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    metaDataIconsFlex: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default function AssetsCmoManualAlertsBulkActionsModal({
    isOpen,
    setOpen,
    getAssetData,
    queryParams,
    isPerformer,
}) {
    const classes = useStyles();

    const [alertMsgInput, setAlertMsgInput] = useState('');
    const [alertMsgSymCount, setAlertMsgSymCount] = useState(0);
    const [manualAlerts, setManualAlerts] = useState([]);
    const [isTypesDropdwnVisible, setisTypesDropdwnVisible] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const filtersModel = useSelector((state) => state.fugaReducers.filters);

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const handleClose = async (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);

            if (manualAlerts.length > 0) {
                await saveRequest();
                getAssetData();
            }
        }
    };

    const handleInputChange = (e) => {
        const value = e.currentTarget.value;
        if (isSymbolsCountValid(value, handleTooltipOpen, setTooltipOpen, setAlertMsgSymCount)) {
            setAlertMsgInput(value);
        }
    };

    const saveRequest = async () => {
        const commonQueryParams = {
            cmoId: queryParams.cmoId,
            claimMethod: queryParams.claimMethod,
        };

        const commonBodyParams = {
            ...getFiltersToSend(filtersModel),
            includeAssetIds: queryParams.assetsList.map(({ assetId, clientId }) => ({
                assetId,
                clientId,
            })),
            excludeAssetIds: queryParams.excludeList.map(({ assetId, clientId }) => ({
                assetId,
                clientId,
            })),
            assetAlerts: manualAlerts,
            searchString: filtersModel.searchString,
        };

        const actionFunction = isPerformer
            ? raiseBulkAlertForPerformer
            : raiseBulkAlertForRightsHolder;

        await actionFunction(commonQueryParams, commonBodyParams);
    };

    const handleAlertInputSave = async () => {
        manualAlerts.push({
            alertType: selectedValue.beValue,
            alertMessage: alertMsgInput,
        });
        setAlertMsgInput('');
        setisTypesDropdwnVisible(false);
        setSelectedValue(null);
        setAlertMsgSymCount(0);
    };

    const handleAddNewAlertBtn = () => {
        setisTypesDropdwnVisible(true);
    };

    const handleAlertInputCancel = () => {
        setSelectedValue(null);
        setAlertMsgInput('');
        setAlertMsgSymCount(0);
    };

    const handleAlertDelete = (index) => {
        const updatedManualAlerts = manualAlerts.filter((_, alertIndex) => alertIndex !== index);
        setManualAlerts(updatedManualAlerts);
    };

    const getMetaDataByAlertCategory = (category) => {
        return manualAlertsItemsList.find((el) => el.beValue === category) || {};
    };

    return (
        <Dialog onClose={handleClose} open={isOpen} maxWidth="md" disableEscapeKeyDown>
            <DialogTitle disableTypography onClose={handleClose} className={classes.title}>
                <Typography variant="h6">
                    Add alerts to {queryParams.assetsList.length} assets
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                {manualAlerts.map((item, index) => {
                    const alertMetaData = getMetaDataByAlertCategory(item.alertType);

                    if (item.alertType !== 'RESOLVED') {
                        return (
                            <div key={index + item.alertType} className={classes.alertRow}>
                                <div className={classes.actionNDateContainer}>
                                    <div className={classes.metaDataIconsFlex}>
                                        <div className={classes.metaDataIcons}>
                                            {alertMetaData.coloredIcon &&
                                                alertMetaData.coloredIcon(classes.coloredIcon)}
                                        </div>
                                        <Typography
                                            style={{ color: alertMetaData.color }}
                                            variant="subtitle2"
                                        >
                                            {alertMetaData.title}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={classes.operationsContainer}>
                                    <div>
                                        <Tooltip
                                            title="Text should be less than 5000 charactes"
                                            disableHoverListener={true}
                                            open={tooltipOpen}
                                            className={classes.textField}
                                        >
                                            <TextFieldWrapper
                                                name="alertMsg"
                                                type="text"
                                                multiline
                                                maxRows={10}
                                                value={item.alertMessage || ''}
                                                classNameOpt={classes.textField}
                                                onBlur={() => setTooltipOpen(false)}
                                            />
                                        </Tooltip>
                                        <div className={classes.textCounter}>
                                            <FormHelperText>
                                                {item.alertMessage.length || 0}
                                                /5000
                                            </FormHelperText>
                                        </div>
                                    </div>
                                    <div className={classes.operationsContainer}>
                                        <Tooltip title="Delete alert">
                                            <DeleteOutlineOutlinedIcon
                                                className={classNames(
                                                    classes.operationIcons,
                                                    classes.icon,
                                                )}
                                                onClick={() => handleAlertDelete(index)}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })}

                {isTypesDropdwnVisible && (
                    <CommonDropDownButtonWithFixedLabel
                        btnTitle="Alert Type"
                        manualAlertsItemsList={manualAlertsItemsList.slice(
                            0,
                            manualAlertsItemsList.length - 1,
                        )}
                        customStyles={{
                            color: 'primary',
                            variant: 'text',
                            classStyles: classes.alertDropDownLabel,
                        }}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                    />
                )}

                {!isTypesDropdwnVisible && (
                    <div className={classes.addAlertBtn}>
                        <Button
                            className={classes.addAlertButton}
                            variant="outlined"
                            onClick={handleAddNewAlertBtn}
                        >
                            <AddIcon className={classes.addIcon} />
                            Add new alert
                        </Button>
                    </div>
                )}
                {isTypesDropdwnVisible && (
                    <div className={classes.operationsContainer}>
                        <div>
                            <Tooltip
                                title="Text should be less than 5000 charactes"
                                disableHoverListener={true}
                                open={tooltipOpen}
                            >
                                <div>
                                    <TextFieldWrapper
                                        onChange={handleInputChange}
                                        name="alertMsg"
                                        type="text"
                                        multiline
                                        maxRows={10}
                                        value={alertMsgInput || ''}
                                        isDisabled={!selectedValue}
                                        classNameOpt={classes.textField}
                                        InputProps={{
                                            readOnly: !selectedValue,
                                        }}
                                        onBlur={() => setTooltipOpen(false)}
                                    />
                                </div>
                            </Tooltip>
                            <div className={classes.textCounter}>
                                <FormHelperText>{alertMsgSymCount}/5000</FormHelperText>
                            </div>
                        </div>
                        <div className={classes.operationsContainer}>
                            <Tooltip title="Save this alert">
                                <SaveIconSvg
                                    styles={classNames(classes.saveIcon, classes.operationIcons, {
                                        [classes.disabledIcon]: !selectedValue,
                                    })}
                                    onClickCb={handleAlertInputSave}
                                />
                            </Tooltip>
                            <Tooltip title="Clear text">
                                <CrossIconSvg
                                    styles={classNames(classes.operationIcons, {
                                        [classes.disabledIcon]: !selectedValue,
                                    })}
                                    onClickCb={handleAlertInputCancel}
                                />
                            </Tooltip>
                        </div>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Tooltip title="Select 'Save' or 'Clear text' before closing">
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        color="primary"
                        disableElevation
                    >
                        close
                    </Button>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
}
