import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    title: Yup.string()
        .required('Title is required')
        .max(300, 'Title is too long, maximum 300 characters'),
    upc: Yup.string()
        .required('UPC is required')
        .matches(/^[0-9]{12,14}$/, 'Invalid UPC format'),
    catalogueNumber: Yup.string()
        .required('Catalog number is required')
        .max(300, 'Catalog number is too long, maximum 300 characters'),
    releaseDate: Yup.date().required('Release date is required'),
    recordLabel: Yup.string().required('Record label is required'),
    scppLabelId: Yup.string().required('SCPP label ID is required'),
    displayArtist: Yup.string()
        .required('Display artist is required')
        .max(300, 'Display artist is too long, maximum 300 characters'),
    formatDetails: Yup.string().required('Format details are required'),
    compilation: Yup.boolean().nullable(),
    volumeNumber: Yup.number()
        .required('Volume number is required')
        .integer('Volume number must be an integer')
        .min(1, 'Volume number must be at least 1'),
    trackNumber: Yup.number()
        .required('Track number is required')
        .integer('Track number must be an integer')
        .min(1, 'Track number must be at least 1'),
    pyear: Yup.string()
        .required('PYear is required')
        .matches(/^\d{4}$/, 'PYear must be a 4-digit year')
        .test('year-range', 'PYear must be between 1900 and next year', (value) => {
            const year = parseInt(value, 10);
            const currentYearPlusOne = new Date().getFullYear() + 1;
            return year >= 1900 && year <= currentYearPlusOne;
        }),
    pname: Yup.string()
        .required('PName is required')
        .max(300, 'PName is too long, maximum 300 characters'),
    cyear: Yup.string()
        .nullable()
        .notRequired()
        .matches(/^\d{4}$/, 'CYear must be a 4-digit year')
        .test('year-range', 'CYear must be between 1900 and next year', (value) => {
            if (!value) return true;
            const year = parseInt(value, 10);
            const currentYearPlusOne = new Date().getFullYear() + 1;
            return year >= 1900 && year <= currentYearPlusOne;
        }),
    cname: Yup.string()
        .nullable()
        .notRequired()
        .max(300, 'CName is too long, maximum 300 characters'),
    artists: Yup.array()
        .of(
            Yup.object().shape({
                artistName: Yup.string()
                    .required('Artist name is required')
                    .max(300, 'Artist name is too long, maximum 300 characters'),
                isPrimary: Yup.boolean().required(),
            }),
        )
        .min(1, 'At least one artist is required'),
});

export { validationSchema };
