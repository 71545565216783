import moment from 'moment';
import { roles } from '@data/constants';
import cerberusUtils from './cerberusUtils';

const { getUserRole } = cerberusUtils;

class Utils {
    addMissingCountryRow(cmoClientModel, territoryCode, index) {
        var indexToSet = index || cmoClientModel.dataFromBE.length;
        return {
            key: indexToSet,
            territory: territoryCode,
            territoryCode: territoryCode,
            deal_method: cmoClientModel.claimMethod,
            rights: ['All rights'],
            isActive: false,
            cmo_name: '',
        };
    }

    isActiveInterval(startDate, endDate) {
        return (
            moment(startDate, 'YYYY-MM-DD') &&
            moment(endDate, 'YYYY-MM-DD') &&
            moment() < moment(endDate, 'YYYY-MM-DD') &&
            moment(startDate, 'YYYY-MM-DD') < moment()
        );
    }

    checkRolesAndPermissions(role, pageName) {
        const lowerRole = role.toLowerCase();
        const roleInfo = roles.find((rl) => rl.name === lowerRole);
        if (!roleInfo) return false;
        if (roleInfo.allPermissions) return true;
        const hasPermissionForPage = roleInfo.permissions.find((page) => page === pageName);
        return !!hasPermissionForPage;
    }

    checkItemRolesAndPermissions(itemName) {
        const lowerRole = getUserRole();
        const roleInfo = roles.find((rl) => rl.name === lowerRole);
        if (!roleInfo) return false;
        if (roleInfo.allPermissions) return true;
        return !roleInfo.excludedItems.find((item) => item === itemName);
    }
    getDefaultTabIdForClientPage(tabName, panes, permissions) {
        const lowerRole = getUserRole();
        const roleInfo = roles.find((rl) => rl.name === lowerRole);
        if (!roleInfo) return 0;
        if (roleInfo.allPermissions) return 0;
        if (!roleInfo.defaultTab) return 0;
        const tabDescription = Object.keys(panes).find((paneName) => paneName === tabName);
        const defaultTabWithPermission = permissions.join().toLowerCase().includes(tabDescription);
        if (tabDescription && roleInfo.defaultTab === tabDescription && defaultTabWithPermission) {
            const tabObj = panes[tabDescription];
            return tabObj.id;
        } else {
            return this.getSmallestTabIdByPermissionsListForClientPage(permissions, panes);
        }
    }

    getTabIdByNameForClientPage(tabName, panes) {
        const tabDescription = Object.keys(panes).find((paneName) => paneName === tabName);
        if (tabDescription) {
            const tabObj = panes[tabDescription];
            return tabObj.id;
        } else {
            return 0;
        }
    }

    getSmallestTabIdByPermissionsListForClientPage(permissions, panes) {
        const permissionsInLowerCase = permissions.map((perm) => perm.toLowerCase()).join();
        const tabNames = Object.keys(panes).filter((tabName) => {
            return permissionsInLowerCase.includes(tabName);
        });
        const tabsWithPermissions = Object.keys(panes)
            .filter((tabName) => tabNames.includes(tabName))
            .map((tabWithPermission) => panes[tabWithPermission]);
        return Math.min(...tabsWithPermissions.map((tab) => tab.id));
    }

    checkPermissionsOnPage(permissionsArray, permissionValue) {
        return permissionsArray.includes(permissionValue);
    }
}

export default new Utils();
