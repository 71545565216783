import React, { useCallback, useState } from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import CommonTableGrid from '@common/CommonTableGrid';
import { useDispatch } from 'react-redux';
import OverflowTip from '@common/OverflowTip';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import NewRhOwnershipModal from './NewRhOwnershipModal';
import CommonConfirmationDialog from '@common/CommonConfirmationDialog';
import { deleteRhOwnership, getAssetMetadataById } from '@actions/apiActions';

const useStyles = makeStyles(() => ({
    sectionTitle: {
        marginBottom: '16px',
    },
    actionBtn: {
        '&:hover': {
            cursor: 'pointer',
        },
        width: '18px',
        height: '18px',
        color: '#5F5F60',
    },
}));

const headers = [
    {
        title: 'Rights Holders',
        dataIndex: 'rhName',
    },
    {
        title: 'Territories',
        dataIndex: 'territories',
    },
    {
        title: 'Category (contract)',
        dataIndex: 'category',
    },
    {
        title: '%',
        dataIndex: 'percent',
    },
    {
        title: 'Start Date',
        dataIndex: 'startDate',
    },
    {
        title: 'End Date',
        dataIndex: 'endDate',
    },
    {
        title: '',
        dataIndex: '',
        hasIcon: true,
    },
];

export default function AssetMetaDataRightsHoldersSection({
    rhOwnershipDataFromBE,
    dataItem,
    rhOwnershipSaveResult,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isRhOwnModalEditable, setIsRhOwnModalEditable] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedRhOwnershipRowDataItem, setSelectedRhOwnershipRowDataItem] = useState({});
    const [showRhOwnershipModal, setShowRhOwnershipModal] = useState(false);

    const openModal = () => {
        setShowRhOwnershipModal(true);
    };

    const tableReloadOnSuccess = () => {
        dispatch(getAssetMetadataById(dataItem.assetId));
    };

    const deleteOwnership = () => {
        dispatch(
            deleteRhOwnership(
                selectedRhOwnershipRowDataItem.rhOwnId,
                dataItem.assetId,
                tableReloadOnSuccess,
            ),
        );
    };

    const getSpecificData = useCallback((headerItem, dataItem) => {
        const columnTitle = headerItem.title.toLowerCase();

        if (columnTitle === '') {
            const handleEditClick = () => {
                setSelectedRhOwnershipRowDataItem(dataItem);
                setShowRhOwnershipModal(true);
                setIsRhOwnModalEditable(true);
            };
            const handleDeleteClick = () => {
                setSelectedRhOwnershipRowDataItem(dataItem);
                setIsDeleteDialogOpen(true);
            };

            return (
                <div style={{ display: 'flex' }}>
                    <div onClick={handleEditClick}>
                        <EditOutlined
                            className={classes.actionBtn}
                            style={{ marginRight: '7px' }}
                        />
                    </div>
                    <div onClick={handleDeleteClick}>
                        <DeleteOutline className={classes.actionBtn} />
                    </div>
                </div>
            );
        } else {
            return <OverflowTip>{dataItem[headerItem.dataIndex] || ''}</OverflowTip>;
        }
    });

    return (
        <>
            <Typography variant="h6" className={classes.sectionTitle}>
                Rights Holders
            </Typography>
            {rhOwnershipSaveResult.isSuccessful && (
                <Alert
                    style={{ marginBottom: '16px' }}
                    variant="outlined"
                    severity={rhOwnershipSaveResult.hasWarning ? 'warning' : 'success'}
                >
                    {`${
                        rhOwnershipSaveResult?.isDeleted
                            ? 'Rights holder ownership successfully deleted.'
                            : 'Rights holder added and saved to the asset.'
                    }${
                        rhOwnershipSaveResult.hasWarning
                            ? ' This asset needs to be re-registered with one or more CMOs'
                            : ''
                    }`}
                </Alert>
            )}
            <CommonTableGrid
                dataList={rhOwnershipDataFromBE}
                headerList={headers}
                preferences={{
                    loadDataByPageCb: {
                        // no need to pass params here
                    },
                }}
                selection={{
                    isSelectable: false,
                }}
                cellDataCb={getSpecificData}
                isInSubModal={true}
                rowsPerPageOff={true}
                tablePaginationOff={true}
                footerAddition={{
                    itemComponent: (
                        <Button
                            variant="outlined"
                            color="primary"
                            disableElevation
                            disableRipple
                            onClick={openModal}
                        >
                            <AddIcon style={{ margin: '0 10px 0 0' }} />
                            Add Rights Holder
                        </Button>
                    ),
                }}
            />
            {showRhOwnershipModal && (
                <NewRhOwnershipModal
                    showModal={showRhOwnershipModal}
                    setShowModal={setShowRhOwnershipModal}
                    isEditing={isRhOwnModalEditable}
                    setIsEditing={setIsRhOwnModalEditable}
                    customData={{
                        selectedRowDataItem: { ...selectedRhOwnershipRowDataItem, ...dataItem },
                        onTableReload: {
                            reloadCb: getAssetMetadataById,
                            params: [dataItem.assetId],
                        },
                    }}
                />
            )}
            <CommonConfirmationDialog
                isOpen={isDeleteDialogOpen}
                closeCb={setIsDeleteDialogOpen}
                title="Confirm delete rights holder ownership"
                description={`Would you like to delete '${
                    selectedRhOwnershipRowDataItem?.rhName || ''
                }' ?`}
                onConfirmCb={deleteOwnership}
                custumConfirmText="Delete"
            />
        </>
    );
}
