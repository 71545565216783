import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { getFiltersToSend } from '@pages/AssetsCmoPage/common/common';

const useAssetData = ({ match, fetchAssetsFunction, pageModelName }) => {
    const dispatch = useDispatch();
    const filtersModel = useSelector((state) => state.fugaReducers.filters);
    const queryParams = useSelector((state) => state.fugaReducers.queryParams);
    const pageModel = useSelector((state) => state.fugaReducers[pageModelName]);

    const controllerRef = useRef(new AbortController());

    const getAssetData = useCallback(
        async (clearFilters = false) => {
            controllerRef.current.abort();
            controllerRef.current = new AbortController();
            const signal = controllerRef.current.signal;

            const bodyParams = clearFilters ? {} : getFiltersToSend(filtersModel);

            const updatedQueryParams = { ...queryParams };

            if (queryParams.sort && queryParams.sort.value) {
                const sortFields = queryParams.sort.value.split(',');
                updatedQueryParams.sort = `${sortFields[0]},${queryParams.sort.direction}`;
            } else {
                delete updatedQueryParams.sort;
            }
            delete updatedQueryParams.sortValue;

            if (bodyParams?.isrcs?.length > 0) {
                bodyParams.isrcs = bodyParams?.isrcs?.split(',')?.map((item) => item.trim());
            }

            const action = fetchAssetsFunction(
                match.params.id,
                updatedQueryParams,
                bodyParams,
                signal,
            );

            await dispatch(action);
        },
        [
            dispatch,
            fetchAssetsFunction,
            filtersModel,
            match.params.id,
            pageModel?.searchTerm,
            queryParams,
        ],
    );

    const handlePaginationAndSearch = useCallback(
        (_, updatedParams) => {
            if ('size' in updatedParams) {
                dispatch(changeStateByNestedKey('queryParams', 'size', updatedParams.size));
            }
            if ('page' in updatedParams) {
                dispatch(changeStateByNestedKey('queryParams', 'page', updatedParams.page));
            }
            if ('sort' in updatedParams && 'direction' in updatedParams) {
                dispatch(
                    changeStateByNestedKey('queryParams', 'sort', {
                        value: updatedParams.sort,
                        direction: updatedParams.direction,
                    }),
                );
            }

            getAssetData();
        },
        [dispatch, getAssetData, pageModelName],
    );

    const handleSearch = useCallback(
        (term) => {
            dispatch(changeStateByNestedKey('filters', 'searchString', term));
            dispatch(changeStateByNestedKey('queryParams', 'page', 0));
            getAssetData();
        },
        [dispatch, getAssetData, pageModelName],
    );

    const clearSearch = useCallback(() => {
        dispatch(changeStateByNestedKey('filters', 'searchString', ''));
        getAssetData();
    }, [dispatch, getAssetData, pageModelName]);

    return {
        getAssetData,
        handlePaginationAndSearch,
        handleSearch,
        clearSearch,
        pageModel,
    };
};

export default useAssetData;
