import { MAINTENANCE_LOCAL_STORAGE_NAME } from '@data/globalConstants';
import { toast } from 'react-toastify';
import cerberusUtils from '@utils/cerberusUtils';
import helper from '@utils/helper';

const { getUserRole, setUserRole, setCerberusToken, setVarToLocalStorage, getVarFromLocalStorage } =
    cerberusUtils;

function showToastError(errorMessage) {
    toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
}

function handleCommonResponses(res) {
    if (res.status === 401) {
        setUserRole('');
        setCerberusToken('');
        window.location.assign('/login');
        throw new Error('Unauthorized');
    }

    if (res.status === 503) {
        const isOnMaintenance = getVarFromLocalStorage(MAINTENANCE_LOCAL_STORAGE_NAME);
        if (!isOnMaintenance) {
            setVarToLocalStorage(MAINTENANCE_LOCAL_STORAGE_NAME, true);
            window.location.assign('/maintenance');
        }
        throw new Error('Service Unavailable');
    }

    const role = res.headers.get('Nr-Role')?.toLowerCase();
    if (role && role !== getUserRole()) {
        setUserRole(role);
        // TODO: Consider reloading the page if necessary
    }
}

async function parseResponse(res, downloadResp = false) {
    if (downloadResp) {
        const blob = await res.blob();
        helper.downloadFileFromResponse(blob, res.headers.get('content-disposition'));
        return blob;
    }
    const text = await res.text();
    return text ? JSON.parse(text) : { ok: true };
}

async function parseErrorResponse(res) {
    const errorResult = await res.json().catch(() => ({}));
    const errorMessage = errorResult?.data?.error || errorResult.error || 'An error occurred';
    const error = new Error(errorMessage);
    error.response = errorResult;
    return error;
}

export { showToastError, handleCommonResponses, parseResponse, parseErrorResponse };
