import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

export const YearPickerManager = ({ open, onClose, value, onChange }) => {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                open={open}
                onClose={onClose}
                views={['year']}
                value={value ? moment(value, 'YYYY') : null}
                onChange={onChange}
                style={{ display: 'none' }}
            />
        </MuiPickersUtilsProvider>
    );
};
