import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { Grid } from '@material-ui/core';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { YearPickerManager } from '@common/YearPickerManager';
import { useYearPicker } from '@hooks/use-year-picker';
import { ASSET_METADATA_FIELDS } from '@utils/constants/AssetMetadataEditModal';

export function AssetMetaDataCopyrightSection() {
    const {
        control,
        formState: { errors },
        setValue,
        getValues,
        trigger,
    } = useFormContext();

    const { activeField, isYearPickerOpen, openYearPicker, closeYearPicker, handleYearChange } =
        useYearPicker();

    return (
        <Grid item container xs={12} spacing={3}>
            {/* Recording Year */}
            <Grid item xs={12} sm={3}>
                <Controller
                    name={ASSET_METADATA_FIELDS.pyear}
                    control={control}
                    render={({ field }) => (
                        <TextFieldWrapper
                            {...field}
                            label="℗ Recording copyright year (PYear)"
                            fullWidth
                            required
                            onClick={() => openYearPicker('pyear')}
                            readOnly
                            error={!!errors.pyear}
                            helperText={errors.pyear ? errors.pyear.message : ''}
                        />
                    )}
                />
            </Grid>
            {/* ℗ Recording copyright text (PName) */}
            <Grid item xs={12} sm={9}>
                <Controller
                    name={ASSET_METADATA_FIELDS.pname}
                    control={control}
                    render={({ field }) => (
                        <TextFieldWrapper
                            {...field}
                            label="℗ Recording copyright text (PName)"
                            fullWidth
                            required
                            error={!!errors.pname}
                            helperText={errors.pname ? errors.pname.message : ''}
                        />
                    )}
                />
            </Grid>
            {/* Year Picker */}
            <YearPickerManager
                open={isYearPickerOpen}
                onClose={closeYearPicker}
                value={getValues(activeField || '')}
                onChange={(date) => handleYearChange(date, setValue, trigger, activeField)}
            />
        </Grid>
    );
}
