import React, { useEffect, useState } from 'react';
import {
    FormControl,
    Grid,
    Typography,
    Checkbox,
    FormControlLabel,
    FormGroup,
    RadioGroup,
    FormLabel,
    makeStyles,
    Radio,
    MenuItem,
    Select,
    InputLabel,
    FormHelperText,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import moment from 'moment';
import { getGenres, getLanguages } from '@api/assetController';
import { getAllTerritoriesAPI } from '@api/territoriesController';
import { useYearPicker } from '@hooks/use-year-picker';
import { YearPickerManager } from '@common/YearPickerManager';
import { ASSET_METADATA_FIELDS } from '@utils/constants/AssetMetadataEditModal';

const useStyles = makeStyles((theme) => ({
    mb2: {
        marginBottom: theme.spacing(2),
    },
}));

const radioOptions = [
    { value: 'track', label: 'Track' },
    { value: 'classical_track', label: 'Classical track' },
    { value: 'ringtone', label: 'Ringtone' },
    { value: 'music_video', label: 'Music video' },
];

export function AssetMetaDataRecordingDetailsSection() {
    const classes = useStyles();

    const {
        control,
        formState: { errors },
        setValue,
        getValues,
        trigger,
    } = useFormContext();

    const [genres, setGenres] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [countries, setCountries] = useState([]);

    const { activeField, isYearPickerOpen, openYearPicker, closeYearPicker, handleYearChange } =
        useYearPicker();

    useEffect(() => {
        const getSelectData = async () => {
            const genresData = await getGenres();
            const languagesData = await getLanguages();
            const territories = await getAllTerritoriesAPI();

            const countries = territories.data
                .flatMap((region) => region.territories.map((territory) => territory.name))
                .sort((a, b) => a.localeCompare(b));

            setCountries(countries);
            setGenres(genresData.data.genres);
            setLanguages(languagesData.data.languages.sort((a, b) => a.localeCompare(b)));
        };

        getSelectData();
    }, []);

    return (
        <Grid container spacing={3}>
            {/* Duration Field */}
            <Grid item xs={12} sm={2}>
                <Controller
                    name={ASSET_METADATA_FIELDS.duration}
                    control={control}
                    render={({ field }) => (
                        <TextFieldWrapper
                            {...field}
                            label="Duration"
                            fullWidth
                            required
                            error={!!errors.duration}
                            helperText={errors.duration ? errors.duration.message : ''}
                        />
                    )}
                />
            </Grid>

            {/* Type Field */}
            <Grid item xs={12}>
                <FormControl
                    component="fieldset"
                    error={!!errors.contentType}
                    style={{ width: '100%' }}
                    required
                >
                    <FormLabel component="legend">Type</FormLabel>
                    <Controller
                        name={ASSET_METADATA_FIELDS.contentType}
                        control={control}
                        render={({ field }) => (
                            <RadioGroup
                                {...field}
                                row
                                className={classes.mb2}
                                // Workaround to trigger validation, the bug is in react-hook-form when select radioGroup don't trigger validation for some reason
                                onChange={(e) => {
                                    field.onChange(e.target.value);
                                    trigger(ASSET_METADATA_FIELDS.contentType);
                                }}
                            >
                                {radioOptions.map((option) => (
                                    <FormControlLabel
                                        key={option.value}
                                        value={option.value}
                                        control={<Radio size="small" color="primary" />}
                                        label={option.label}
                                    />
                                ))}
                            </RadioGroup>
                        )}
                    />
                    {errors.contentType && (
                        <Typography color="error" variant="caption">
                            {errors.contentType.message}
                        </Typography>
                    )}
                </FormControl>
            </Grid>

            {/* Remastered, Medley, Contains Sample (Checkboxes) */}
            <Grid item xs={12}>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Controller
                                name={ASSET_METADATA_FIELDS.isRemastered}
                                control={control}
                                render={({ field }) => (
                                    <Checkbox {...field} checked={field.value} color="primary" />
                                )}
                            />
                        }
                        label="Remastered"
                    />
                    <FormControlLabel
                        control={
                            <Controller
                                name={ASSET_METADATA_FIELDS.medley}
                                control={control}
                                render={({ field }) => (
                                    <Checkbox {...field} checked={field.value} color="primary" />
                                )}
                            />
                        }
                        label="Medley"
                    />
                    <FormControlLabel
                        control={
                            <Controller
                                name={ASSET_METADATA_FIELDS.containsSample}
                                control={control}
                                render={({ field }) => (
                                    <Checkbox {...field} checked={field.value} color="primary" />
                                )}
                            />
                        }
                        label="Contains Sample"
                    />
                </FormGroup>
            </Grid>

            {/* Country of Recording */}
            <Grid item xs={12} sm={4}>
                <Controller
                    name={ASSET_METADATA_FIELDS.recordingCountry}
                    control={control}
                    render={({ field }) => (
                        <FormControl fullWidth error={errors.recordingCountry}>
                            <InputLabel shrink>Country of Recording</InputLabel>
                            <Select
                                {...field}
                                label="Country of Recording"
                                fullWidth
                                required
                                error={!!errors.recordingCountry}
                                helperText={
                                    errors.recordingCountry ? errors.recordingCountry.message : ''
                                }
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>Select Country</em>
                                </MenuItem>
                                {countries.map((country) => (
                                    <MenuItem key={country} value={country}>
                                        {country}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.recordingCountry && (
                                <FormHelperText error variant="caption">
                                    {errors.recordingCountry.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                />
            </Grid>

            {/* Commissioning Country */}
            <Grid item xs={12} sm={4}>
                <Controller
                    name={ASSET_METADATA_FIELDS.commissioningCountry}
                    control={control}
                    render={({ field }) => (
                        <FormControl fullWidth error={!!errors.commissioningCountry}>
                            <InputLabel shrink>Commissioning Country</InputLabel>
                            <Select
                                {...field}
                                fullWidth
                                required
                                error={!!errors.commissioningCountry}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>Select Country</em>
                                </MenuItem>
                                {countries.map((country) => (
                                    <MenuItem key={country} value={country}>
                                        {country}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.commissioningCountry && (
                                <FormHelperText error variant="caption">
                                    {errors.commissioningCountry.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                />
            </Grid>

            <Grid item xs={12} container spacing={3}>
                {/* Recording Date (Calendar) */}
                <Grid item xs={12} sm={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Controller
                            name={ASSET_METADATA_FIELDS.recordingDate}
                            control={control}
                            render={({ field }) => (
                                <KeyboardDatePicker
                                    {...field}
                                    label="Recording Date"
                                    format="DD/MM/yyyy"
                                    fullWidth
                                    error={!!errors.recordingDate}
                                    helperText={
                                        errors.recordingDate ? errors.recordingDate.message : ''
                                    }
                                    value={field.value || null}
                                    onChange={(date) =>
                                        field.onChange(moment(date).format('YYYY-MM-DD'))
                                    }
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            )}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                {/* Recording Year */}
                <Grid item xs={12} sm={3}>
                    <Controller
                        name={ASSET_METADATA_FIELDS.recordingYear}
                        control={control}
                        render={({ field }) => (
                            <TextFieldWrapper
                                {...field}
                                label="Recording Year"
                                fullWidth
                                required
                                onClick={() => openYearPicker('recordingYear')}
                                readOnly
                                error={!!errors.recordingYear}
                                helperText={
                                    errors.recordingYear ? errors.recordingYear.message : ''
                                }
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Controller
                    name={ASSET_METADATA_FIELDS.genre}
                    control={control}
                    render={({ field }) => (
                        <FormControl fullWidth required error={!!errors.genre}>
                            <InputLabel shrink>Genre</InputLabel>
                            <Select {...field} fullWidth displayEmpty>
                                <MenuItem value="">
                                    <em>Select Genre</em>
                                </MenuItem>
                                {genres.map((genre) => (
                                    <MenuItem key={genre} value={genre.toLowerCase()}>
                                        {genre}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.genre && (
                                <FormHelperText error variant="caption">
                                    {errors.genre.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <Controller
                    name={ASSET_METADATA_FIELDS.language}
                    control={control}
                    render={({ field }) => (
                        <FormControl fullWidth error={errors.language}>
                            <InputLabel shrink>Language of Performance</InputLabel>
                            <Select
                                {...field}
                                fullWidth
                                required
                                error={!!errors.language}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>Select Language</em>
                                </MenuItem>
                                {languages.map((language) => (
                                    <MenuItem key={language} value={language}>
                                        {language}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.language && (
                                <FormHelperText error variant="caption">
                                    {errors.language.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                />
            </Grid>

            {/* Year Picker */}
            <YearPickerManager
                open={isYearPickerOpen}
                onClose={closeYearPicker}
                value={getValues(activeField || '')}
                onChange={(date) => handleYearChange(date, setValue, trigger, activeField)}
            />
        </Grid>
    );
}
