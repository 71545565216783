import { TextField, makeStyles } from '@material-ui/core';
import React from 'react';

const useClasses = makeStyles({
    customReadOnlyTextFields: {
        '& .MuiInput-underline': {
            pointerEvents: 'none',
        },
    },
    inputHeight: {
        height: '3em', // To prevent jumping effect when textfield has error message
    },
});

export default function TextFieldWrapper(props) {
    const { classNameOpt, isDisabled, ...textFieldProps } = props;
    const classes = useClasses();
    const clasStr = `${textFieldProps.className} ${classNameOpt} ${isDisabled ? classes.customReadOnlyTextFields : ''} ${classes.inputHeight}`;
    const isSelectField = textFieldProps.select;
    return (
        <TextField
            {...textFieldProps}
            className={clasStr}
            InputLabelProps={{
                ...textFieldProps.InputLabelProps,
                shrink: isSelectField || Boolean(textFieldProps.value),
            }}
        />
    );
}
