import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import CommonTableGrid from '@common/CommonTableGrid';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeStateByNestedKey } from '@actions/fugaActions';
import { withRouter } from 'react-router-dom';
import CommonSearchInput from '@common/CommonSearchInput';
import StatusHistoryModal from '@common/modals/StatusHistoryModal';
import CmoAssetRegistrationManualAlertsModal from '@common/modals/CmoAssetRegistrationManualAlertsModal';
import AssetsCmoTableFiltersWrapper from './AssetsCmoTableFiltersWrapper';
import { getPerformerAssets, getRightsHolderAssets } from '@actions/assetsRegistration';
import BulkActionsDropDownButtons from '@common/BulkActionsDrownButtons';
import { AssetsCmoTableRow } from '@pages/AssetsCmoPage/AssetsCmoTableRow';
import { AssetsRegistrationCommentsModal } from '@common/modals/AssetsRegistrationCommentsModal';
import { ClaimMethodSelector } from '@pages/AssetsCmoPage/ClaimMethodSelector';
import useAssetData from '@hooks/useAssetData';

const useStyles = makeStyles((theme) => ({
    tableOptions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    activeButton: {
        color: theme.palette.grey.content,
        backgroundColor: theme.palette.grey.background,

        '&:hover': {
            backgroundColor: theme.palette.grey.hover,
        },
    },
    dateColumn: {
        padding: '15px 8px',
    },
}));

const getHeaders = (isPerformer, classes) => [
    {
        title: 'Client',
        dataIndex: 'clientName',
    },
    {
        title: 'Artist',
        dataIndex: 'artist',
    },
    {
        title: 'Track title/ISRC',
        dataIndex: ['trackTitle', 'isrcCode'],
    },
    {
        title: 'Territories',
        dataIndex: 'claimTerritories',
    },
    {
        title: 'Start date',
        dataIndex: 'startDate',
        className: classes.dateColumn,
    },
    {
        title: 'End date',
        dataIndex: 'endDate',
        className: classes.dateColumn,
    },
    {
        title: 'Registration status',
        dataIndex: 'claimStatus',
        wideTableCell: true,
    },
    {
        title: 'Modified date',
        dataIndex: 'lastModified',
    },
    {
        title: 'Status update',
        dataIndex: 'eventDate',
    },
    {
        title: '',
        dataIndex: '',
    },
];

function AssetsCmoPage({ match, cmoType }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(false);
    const [dialogDataItem, setDialogDataItem] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isCommentsDialogOpen, setIsCommentsDialogOpen] = useState(false);
    const [claimMethodValue, setClaimMethodValue] = useState({
        value: 'AGENT',
        radioValue: 'agent',
    });

    const dispatch = useDispatch();
    const queryParams = useSelector((state) => state.fugaReducers.queryParams);

    const selectedAssets = useRef([]);
    const setSelectedAssets = (newValue) => {
        selectedAssets.current = newValue;
    };
    const excludedAssets = useRef([]);
    const setExcludedAssets = (newValue) => {
        excludedAssets.current = newValue;
    };

    const isPerformer = cmoType === 'PERFORMER';
    const pageModelName = isPerformer ? 'performerCmoPage' : 'cmoPage';
    const fetchAssetsFunction = isPerformer ? getPerformerAssets : getRightsHolderAssets;

    const { getAssetData, handlePaginationAndSearch, handleSearch, clearSearch, pageModel } =
        useAssetData({
            match,
            fetchAssetsFunction,
            pageModelName,
        });

    const clearAllFilters = () => {
        getAssetData(true);
    };

    useEffect(() => {
        getAssetData();
    }, []);

    const handleHistoryClose = () => {
        dispatch(changeStateByNestedKey(pageModelName, 'registrationHistory', []));
        setAnchorEl(null);
    };

    const handleTableSearch = (term) => {
        handleSearch(term);
        setSelectedAssets([]);
        dispatch(
            changeStateByNestedKey(pageModelName, 'downloadAllCmoAssets', {
                requestFlag: false,
                localSelectAll: false,
            }),
        );
    };

    const clearTableSearch = () => {
        clearSearch();
        setSelectedAssets([]);
        dispatch(
            changeStateByNestedKey(pageModelName, 'downloadAllCmoAssets', {
                requestFlag: false,
                localSelectAll: false,
            }),
        );
    };

    const openHistoryInfo = Boolean(anchorEl);

    return (
        <div>
            {!isPerformer && (
                <ClaimMethodSelector
                    claimMethodValue={claimMethodValue}
                    setClaimMethodValue={setClaimMethodValue}
                    getAssetData={getAssetData}
                    isPerformer={isPerformer}
                    pageModel={pageModel}
                />
            )}
            <div className={classes.tableOptions}>
                <CommonSearchInput
                    placeHolderText={isPerformer ? 'Performer Client' : 'Search Rights Holder'}
                    onSearch={handleTableSearch}
                    term={pageModel.searchTerm}
                    onClearCb={clearTableSearch}
                />
                <BulkActionsDropDownButtons
                    cmoId={match.params.id}
                    getAssetData={getAssetData}
                    queryParams={{
                        cmoId: match.params.id,
                        claimMethod: claimMethodValue.value,
                        downloadAll: pageModel.downloadAllCmoAssets.requestFlag,
                        assetsList: selectedAssets.current,
                        excludeList: excludedAssets.current,
                        searchTerm: pageModel.searchTerm,
                    }}
                    hasSpecificTemplate={pageModel.cmoInfo.hasTemplate}
                    isPerformer={isPerformer}
                />
            </div>
            <AssetsCmoTableFiltersWrapper
                tableReloadCb={getAssetData}
                clearAllFilters={clearAllFilters}
                isPerformer={isPerformer}
            />
            <CommonTableGrid
                dataList={pageModel.dataFromBE}
                headerList={getHeaders(isPerformer, classes)}
                loading={pageModel.tableIsLoading}
                sortPrefs={{
                    storeName: 'queryParams',
                    storeSubName: 'sort',
                    value: queryParams.sort,
                }}
                cellDataCb={(headerItem, dataItem) => (
                    <AssetsCmoTableRow
                        headerItem={headerItem}
                        dataItem={dataItem}
                        pageModelName={pageModelName}
                        setAnchorEl={setAnchorEl}
                        setDialogDataItem={setDialogDataItem}
                        setIsCommentsDialogOpen={setIsCommentsDialogOpen}
                        setIsDialogOpen={setIsDialogOpen}
                        getAssetData={getAssetData}
                    />
                )}
                preferences={{
                    loadDataByPageCb: {
                        dispatchFn: dispatch,
                        callbackFn: handlePaginationAndSearch,
                        argumentsList: [{}],
                        noDispatch: true,
                    },
                }}
                isInSubModal={false}
                paginationBE
                trackPagination={{
                    isPaginationTrackingOn: true,
                    paginationTrackingCB: () => {},
                }}
                searchTerm={pageModel.searchTerm}
                selection={{
                    isSelectable: true,
                    dispatchFn: dispatch,
                    callbackFn: changeStateByNestedKey,
                    selectedRows: selectedAssets.current,
                    setSelectedRows: setSelectedAssets,
                    downloadAllCmoAssets: pageModel.downloadAllCmoAssets,
                    fullRowData: true,
                    filter: ['assetId', 'clientId'],
                    isSearching: !!pageModel.searchTerm,
                    excludedRows: excludedAssets.current,
                    setExcludedAssets: setExcludedAssets,
                    storeName: pageModelName,
                }}
            />
            <StatusHistoryModal
                anchorEl={anchorEl}
                onClose={handleHistoryClose}
                isOpen={openHistoryInfo}
                selectedRowDataItem={pageModel.selectedRowDataItem}
                storeName={pageModelName}
                contentData={pageModel.registrationHistory}
                isAsset={true}
            />
            {isDialogOpen && (
                <CmoAssetRegistrationManualAlertsModal
                    isOpen={isDialogOpen}
                    setOpen={setIsDialogOpen}
                    dataItem={dialogDataItem}
                    pageModelName={pageModelName}
                    getAssetData={getAssetData}
                />
            )}
            {isCommentsDialogOpen && (
                <AssetsRegistrationCommentsModal
                    isOpen={isCommentsDialogOpen}
                    setOpen={setIsCommentsDialogOpen}
                    pageModelName={pageModelName}
                    getAssetData={getAssetData}
                />
            )}
        </div>
    );
}

const withUrlPerformersCmoPage = withRouter(AssetsCmoPage);
export default connect((r) => r)(withUrlPerformersCmoPage);
