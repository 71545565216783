import userData from '@data/userData';
import Fetch from '@utils/Fetch';

const CONTROLLER_URL = userData.api.baseAssetUrl();

async function getAssetTrackTitleLookup(trackTitle, signal) {
    try {
        const url = new URL(`${CONTROLLER_URL}/track-title/lookup`);
        url.searchParams.append('trackTitle', trackTitle);

        return Fetch.fetchWithErrorToast({
            url: url.toString(),
            signal,
        });
    } catch (e) {
        return [];
    }
}

async function getArtistLookup(artist, signal) {
    try {
        const url = new URL(`${CONTROLLER_URL}/artist/lookup`);
        url.searchParams.append('artist', artist);

        return Fetch.fetchWithErrorToast({
            url,
            signal,
        });
    } catch (e) {
        return [];
    }
}

async function getGenres() {
    try {
        const url = new URL(`${CONTROLLER_URL}/genres`);

        return Fetch.fetchWithErrorToast({
            url,
            errorMessage: 'Failed to get genres',
        });
    } catch (e) {
        return {
            genres: [],
        };
    }
}

async function getLanguages() {
    try {
        const url = new URL(`${CONTROLLER_URL}/languages-of-performance`);

        return Fetch.fetchWithErrorToast({
            url,
            errorMessage: 'Failed to get languages',
        });
    } catch (e) {
        return {
            languages: [],
        };
    }
}

async function getAssetClientNameLookup({ clientName, signal, isPerformer }) {
    try {
        const url = new URL(
            `${CONTROLLER_URL}/client-name/${isPerformer ? 'performer' : 'rh'}/lookup`,
        );
        url.searchParams.append('clientName', clientName);

        return Fetch.fetchWithErrorToast({
            url: url.toString(),
            signal,
        });
    } catch (e) {
        return [];
    }
}

function getRHAssetsByIdAPI(
    id,
    queryParams = { sort: '', page: '', size: '' },
    requestBody = { isrcs: [], searchString: '' },
) {
    const url = new URL(`${CONTROLLER_URL}/rh/${id}`);
    const page = queryParams.page || queryParams.page >= 0;

    url.search = new URLSearchParams({
        ...(page ? { page: queryParams.page } : {}),
        ...(queryParams.size ? { size: queryParams.size } : {}),
        ...(queryParams.sort ? { sort: queryParams.sort } : {}),
    });

    return Fetch.fetchWithErrorToast({
        url,
        options: {
            method: 'POST',
            body: JSON.stringify(requestBody),
        },
    });
}

function getAssetByPerformerIdAPI(
    id,
    { page = 0, size = '', sort = '', direction = '' } = {},
    requestBody = { isrcs: [], searchString: '' },
) {
    const url = new URL(`${CONTROLLER_URL}/performer/${id}`);

    const pageParam = page || page === 0;

    url.search = new URLSearchParams({
        ...(pageParam ? { page } : {}),
        ...(size ? { size } : {}),
        ...(sort ? { ...sort } : {}),
        ...(direction ? { direction } : {}),
    });

    return Fetch.fetchWithErrorToast({
        url,
        options: {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
        },
        errorMessage: 'Failed to get assets by performer id',
    });
}

function importCSVAPI(files) {
    const data = new FormData();
    data.append('file', files[0]);

    const url = `${CONTROLLER_URL}/upload-with-errors`;

    const options = {
        method: 'POST',
        headers: {
            contentType: 'multipart/form-data',
        },
        body: data,
    };

    return Fetch.fetchWithToasts({
        url,
        options,
        errorMessage: 'Maximum upload size exceeded',
        successMessage: 'File uploaded successfully',
        pendingMessage: 'Uploading file...',
    });
}

export {
    getAssetTrackTitleLookup,
    getArtistLookup,
    getAssetClientNameLookup,
    getAssetByPerformerIdAPI,
    getGenres,
    getLanguages,
    getRHAssetsByIdAPI,
    importCSVAPI,
};
