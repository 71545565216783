import { changeStateByKey, changeStateByNestedKey } from './fugaActions';
import { getClientsListAPI, getCmoConnectionsByClientIdAPI } from '@api/clientController';
import { getAlertMsgByCmoRegIdAPI } from '@api/alertController';
import {
    createContributionAPI,
    createOrUpdateCmoRegistrationAPI,
    createRhOwnershipAPI,
    deleteContributionAPI,
    deleteRhOwnershipAPI,
    editAssetMetadataByIdAPI,
    editContributionAPI,
    editRhOwnershipAPI,
    getAllPerformerNamesForMetadataAPI,
    getAllRhNamesForMetadataAPI,
    getAllRolesAndInstrumentsForMetadataAPI,
    getAssetMetadataByIdAPI,
    getContributionAPI,
    getRhOwnershipByRhOwnIdAPI,
    validateAssetMetadataByIdAPI,
    validateContributionsAPI,
    validateRhOwnershipByRhAndAssetIdAPI,
} from '@api/metadataController';
import { toast } from 'react-toastify';

export function updateNestedField(key1, key2, val, params = {}) {
    return {
        type: 'updateNestedField',
        key1,
        key2,
        val,
        params,
    };
}

// Get

export function getClientsList() {
    return async function (dispatch) {
        const resp = await getClientsListAPI();

        dispatch(changeStateByKey('allClientsList', resp.data));
    };
}

export function getCmoConnectionsByClientId(clientId, updateOptions = {}) {
    return async function (dispatch) {
        const {
            isUpdateble = false,
            arrayName = '',
            idFieldName = '',
            id = -1,
            fieldNameToChange = '',
        } = updateOptions;

        if (!isUpdateble) dispatch(changeStateByNestedKey('cmoConnection', 'tableIsLoading', true));

        const resp = await getCmoConnectionsByClientIdAPI(clientId);

        const popupStatuses = ['EXCLUSIVE_LICENSE_DEAL', 'TO_BE_REGISTERED'];
        const currentDataStatuses = (resp?.data || []).map((data) => data.registrationStatus);
        const isFirstToSubmit = currentDataStatuses.every((el) => popupStatuses.includes(el));
        dispatch(changeStateByNestedKey('cmoConnection', 'isFirstToSubmit', isFirstToSubmit));

        if (isUpdateble) {
            const value =
                (resp?.data || []).find((el) => {
                    return el[idFieldName] === id;
                }) || {};
            if (updateOptions.fieldNamesListToChange) {
                updateOptions.fieldNamesListToChange.map((fieldName) => {
                    dispatch(
                        updateNestedField('cmoConnection', 'dataFromBE', value[fieldName], {
                            arrayName,
                            idFieldName,
                            id,
                            fieldNameToChange: fieldName,
                        }),
                    );
                });
            } else {
                dispatch(
                    updateNestedField('cmoConnection', 'dataFromBE', value[fieldNameToChange], {
                        arrayName,
                        idFieldName,
                        id,
                        fieldNameToChange,
                    }),
                );
            }
        } else {
            dispatch(changeStateByNestedKey('cmoConnection', 'tableIsLoading', false));
            dispatch(
                changeStateByNestedKey('cmoConnection', 'dataFromBE', {
                    content: resp?.data || [],
                    totalElements: resp?.data?.length || 0,
                }),
            );
        }
    };
}

export function getAlertMsgByCmoRegId(cmoRegId) {
    return async function (dispatch) {
        const resp = await getAlertMsgByCmoRegIdAPI(cmoRegId);

        dispatch(changeStateByNestedKey('cmoConnection', 'alertMsgs', resp?.data || []));
    };
}

export function getAssetMetadataById(assetId) {
    return async function (dispatch) {
        const resp = await getAssetMetadataByIdAPI(assetId);

        dispatch(changeStateByNestedKey('assetMetadata', 'dataObjFromBE', resp.data || []));
        const rhOwnershipsToSave = {
            content: resp.data.rhOwnerships ? [...resp.data.rhOwnerships] : [],
        };
        dispatch(
            changeStateByNestedKey('assetMetadata', 'rhOwnershipDataFromBE', rhOwnershipsToSave),
        );
        // Formating instruments so commonTableComponent can read them. To Format Categories
        const contributorsToSave = {
            content:
                (resp.data?.contributions || []).map((contribution) => {
                    const instruments = contribution.instruments;
                    const instrumentsFormated =
                        instruments.length === 1
                            ? instruments[0]
                            : instruments.length > 1
                              ? `${instruments[0]} + ${instruments.length - 1} more`
                              : '';
                    return {
                        ...contribution,
                        instruments: instrumentsFormated,
                        category: (contribution.category + '')
                            .toLocaleLowerCase()
                            .split('_')
                            .join(' ')
                            .replace(/\b\w/g, (match) => match.toUpperCase()),
                    };
                }) || [],
        };
        dispatch(
            changeStateByNestedKey('assetMetadata', 'contributorsDataFromBE', contributorsToSave),
        );
        dispatch(
            changeStateByNestedKey('assetMetadata', 'productsDataFromBE', {
                content: resp.data.products ? [...resp.data.products] : [],
            }),
        );
        dispatch(
            changeStateByNestedKey('assetMetadata', 'artistsDataFromBE', {
                content: resp.data.artists || [],
            }),
        );
    };
}

export function getAllRolesAndInstrumentsForMetadata() {
    return async function (dispatch) {
        const resp = await getAllRolesAndInstrumentsForMetadataAPI();

        dispatch(
            changeStateByNestedKey(
                'assetMetadata',
                'allRolesAndInstrumentsFromBE',
                resp.data.instrumentsRoles || [],
            ),
        );
    };
}

export function getAllRhNamesForMetadata() {
    return async function (dispatch) {
        const resp = await getAllRhNamesForMetadataAPI();

        dispatch(changeStateByNestedKey('assetMetadata', 'allRhNamesFromBE', resp.data || []));
    };
}

export function getAllPerformerNamesForMetadata() {
    return async function (dispatch) {
        const resp = await getAllPerformerNamesForMetadataAPI();
        dispatch(
            changeStateByNestedKey('assetMetadata', 'allPerformerNamesFromBE', resp.data || []),
        );
    };
}

export function getRhOwnershipByRhOwnId(rhOwnId) {
    return async function (dispatch) {
        const resp = await getRhOwnershipByRhOwnIdAPI(rhOwnId);

        dispatch(
            changeStateByNestedKey(
                'assetMetadata',
                'rhOwnershipDettailsObjFromBE',
                resp.data || {},
            ),
        );
    };
}

export function getContribution(performerId, assetId) {
    return async function (dispatch) {
        const resp = await getContributionAPI(performerId, assetId);

        dispatch(
            changeStateByNestedKey('assetMetadata', 'contributorDetailsObjFromBE', resp.data || {}),
        );
    };
}

// Post

export function createOrUpdateCmoRegistration(
    content,
    requestConfs = { update: false, storeName: 'performers', handleCloseCb: null },
) {
    return async function (dispatch) {
        try {
            const resp = await createOrUpdateCmoRegistrationAPI(content, requestConfs.update);

            dispatch(
                changeStateByNestedKey(requestConfs.storeName, 'modalValidations', {
                    msgTerritory: '',
                    msgDate: '',
                }),
            );

            toast.update(resp.toastId, {
                render:
                    resp.message ||
                    `Connection ${requestConfs.update ? 'updated' : 'created'} successfully`,
                type: 'success',
                isLoading: false,
                autoClose: 5000,
            });

            if (requestConfs.handleCloseCb) dispatch(requestConfs.handleCloseCb);
        } catch (e) {
            // console.log('error of the mapping', JSON.stringify(...e.message));
            console.dir(e.message, { depth: null, colors: true });
            console.dir(e.data, { depth: null, colors: true });

            const errors = {
                territories: {
                    msg: 'is outside of supported territories for this Client Deal',
                    msgToShow: 'Your Territories are outside of the Client Deal',
                },
                dates: {
                    msg: 'Territory Period is outside Client Deal term',
                    msgToShow: 'Dates outside of client deal',
                },
                clush: {
                    msg: 'Input territory period is covered by exploitation types from other CMO',
                    msgToShow: 'This Date and Territory combination already exists',
                },
                cmoIds: {
                    msg: 'has been already connected to client deal id',
                    msgToShow:
                        'This CMO, Date and Territory combination already exists for the Claim Party',
                },
            };

            if (!e.data.error) {
                let matched = false;
                for (const [type, messages] of Object.entries(errors)) {
                    if (e.data[0].message && e.data[0].message.includes(messages.msg)) {
                        if (type === 'clush' || type === 'cmoIds') {
                            matched = true;
                            break;
                        } else if (type === 'dates') {
                            dispatch(
                                changeStateByNestedKey(requestConfs.storeName, 'modalValidations', {
                                    msgTerritory: messages.msgToShow,
                                }),
                            );
                            matched = true;
                            break;
                        } else {
                            dispatch(
                                changeStateByNestedKey(requestConfs.storeName, 'modalValidations', {
                                    msgTerritory: messages.msgToShow,
                                }),
                            );
                            matched = true;
                            break;
                        }
                    }
                }

                if (!matched) {
                    dispatch(
                        changeStateByNestedKey(requestConfs.storeName, 'modalValidations', {
                            msgTerritory: '',
                        }),
                    );
                    dispatch(
                        changeStateByKey('infoMessage', {
                            isVisible: true,
                            message: e.data.map((data) => data.message).join(),
                            severity: 'error',
                        }),
                    );
                }
            }
        }
    };
}

export function createRhOwnership(ownershipContent, handleCloseCb, onCreationFailCb) {
    return async function (dispatch) {
        try {
            const resp = await createRhOwnershipAPI(ownershipContent);

            handleCloseCb();
            dispatch(
                changeStateByNestedKey('assetMetadata', 'rhOwnershipSaveResult', {
                    isSuccessful: true,
                    hasWarning: !!resp?.data?.isAssetClaimedEventProcessed,
                }),
            );
        } catch (e) {
            onCreationFailCb(e.message);
        }
    };
}

export function createContribution(contributionContent, assetId, handleCloseCb, onCreationFailCb) {
    return async function (dispatch) {
        try {
            const resp = await createContributionAPI(contributionContent, assetId);

            handleCloseCb();
            dispatch(
                changeStateByNestedKey('assetMetadata', 'contributionSaveResult', {
                    isSuccessful: true,
                    hasWarning: !!resp?.data?.isAssetClaimedEventProcessed,
                }),
            );
        } catch (e) {
            onCreationFailCb(e);
        }
    };
}

export function editAssetMetadataById(
    assetId,
    metadataContent,
    isPerformer = true,
    updateOptions = {},
) {
    return async function (dispatch) {
        const { isUpdateble = false, arrayName = '', idFieldName = '', id = -1 } = updateOptions;

        const resp = await editAssetMetadataByIdAPI(assetId, metadataContent);

        if (isUpdateble) {
            const value = resp?.data || [];
            const storeFieldName = isPerformer ? 'performersRepertoire' : 'repertoires';
            const fieldsMapping = {
                assetTrackTitle: 'trackTitle',
                assetIsrc: 'isrcCode',
                assetDisplayArtists: 'artistList',
                assetTrackVersion: 'versionTitle',
                lastModified: 'lastModified',
            };

            if (isUpdateble) {
                for (const [assetName, tableName] of Object.entries(fieldsMapping)) {
                    dispatch(
                        updateNestedField(storeFieldName, 'dataFromBE', value[assetName], {
                            arrayName,
                            idFieldName,
                            id,
                            fieldNameToChange: tableName,
                        }),
                    );
                }
            }
        }
    };
}

export function editRhOwnership(ownershipContent, rhId, handleCloseCb, onUpdateFailCb) {
    return async function (dispatch) {
        try {
            const resp = await editRhOwnershipAPI(ownershipContent, rhId);
            handleCloseCb();
            dispatch(
                changeStateByNestedKey('assetMetadata', 'rhOwnershipSaveResult', {
                    isSuccessful: true,
                    hasWarning: !!resp?.data?.isAssetClaimedEventProcessed,
                }),
            );
        } catch (e) {
            onUpdateFailCb(e);
        }
    };
}

export function editContribution(contributionContent, assetId, handleCloseCb, onUpdateFailCb) {
    return async function (dispatch) {
        try {
            const resp = await editContributionAPI(contributionContent, assetId);

            handleCloseCb();
            dispatch(
                changeStateByNestedKey('assetMetadata', 'contributionSaveResult', {
                    isSuccessful: true,
                    hasWarning: !!resp?.data?.isAssetClaimedEventProcessed,
                }),
            );
        } catch (e) {
            onUpdateFailCb(e);
        }
    };
}

export function validateAssetMetadataById(
    assetId,
    metadataContent,
    isPerformer = true,
    updateOptions = {},
    closeCb,
) {
    return async function (dispatch) {
        const resp = await validateAssetMetadataByIdAPI(assetId, metadataContent);

        function showToastErrors(response) {
            const { errors, warning, cmoErrors } = response;
            const shownMessages = new Set(); // To track and avoid duplicates

            // Handle errors
            if (errors) {
                Object.keys(errors).forEach((field) => {
                    errors[field].forEach((message) => {
                        const errorMessage = `${field}: ${message}`;
                        if (!shownMessages.has(errorMessage)) {
                            toast.error(errorMessage, { autoClose: false });
                            shownMessages.add(errorMessage);
                        }
                    });
                });
            }

            // Handle warnings (optional, show after errors)
            if (warning) {
                Object.keys(warning).forEach((field) => {
                    warning[field].forEach((message) => {
                        const warningMessage = `${field}: ${message}`;
                        if (!shownMessages.has(warningMessage)) {
                            toast.warn(warningMessage, { autoClose: false });
                            shownMessages.add(warningMessage);
                        }
                    });
                });
            }

            // Handle CMO errors
            if (cmoErrors && cmoErrors.length > 0) {
                cmoErrors.forEach((cmoError) => {
                    const { cmoNames, cmoValidationMessage } = cmoError;
                    const cmoField = cmoValidationMessage.fieldName;
                    const cmoMessage = cmoValidationMessage.validationMessage;
                    const cmoList = cmoNames.join(', ');
                    const cmoErrorMessage = `CMO Validation Error: ${cmoField} - ${cmoMessage} (for ${cmoList})`;

                    if (!shownMessages.has(cmoErrorMessage)) {
                        toast.error(cmoErrorMessage, { autoClose: false });
                        shownMessages.add(cmoErrorMessage);
                    }
                });
            }
        }

        if (resp?.data) {
            showToastErrors(resp.data);
        }

        if (resp?.data.valid) {
            dispatch(editAssetMetadataById(assetId, metadataContent, isPerformer, updateOptions));
            closeCb();
        }
    };
}

export function validateContributions(
    assetId,
    contributionContent,
    handleCloseCb,
    onCreationFailCb,
    isEditing = false,
) {
    return async function (dispatch) {
        const resp = await validateContributionsAPI(assetId, contributionContent);

        dispatch(changeStateByNestedKey('assetMetadata', 'contributionValidations', resp.data));
        if (resp.data.valid) {
            if (isEditing) {
                dispatch(
                    editContribution(contributionContent, assetId, handleCloseCb, onCreationFailCb),
                );
            } else {
                dispatch(
                    createContribution(
                        contributionContent,
                        assetId,
                        handleCloseCb,
                        onCreationFailCb,
                    ),
                );
            }
        }
    };
}

export function validateRhOwnershipByRhAndAssetId(
    rhId,
    assetId,
    ownershipContent,
    handleCloseCb,
    onCreationFailCb,
    isEditing = false,
) {
    return async function (dispatch) {
        const resp = await validateRhOwnershipByRhAndAssetIdAPI(ownershipContent, assetId, rhId);

        dispatch(changeStateByNestedKey('assetMetadata', 'rhOwnershipValidations', resp.data));

        if (resp.data.valid) {
            if (isEditing) {
                dispatch(
                    editRhOwnership(
                        {
                            assetId,
                            ...ownershipContent,
                        },
                        rhId,
                        handleCloseCb,
                        onCreationFailCb,
                    ),
                );
            } else {
                dispatch(
                    createRhOwnership(
                        {
                            assetId,
                            ...ownershipContent,
                        },
                        handleCloseCb,
                        onCreationFailCb,
                    ),
                );
            }
        }
    };
}

export function deleteContribution(performerId, assetId, handleCloseCb) {
    return async function (dispatch) {
        const resp = await deleteContributionAPI(performerId, assetId);

        handleCloseCb();

        dispatch(
            changeStateByNestedKey('assetMetadata', 'contributionSaveResult', {
                isSuccessful: true,
                hasWarning: !!resp?.data?.isAssetClaimedEventProcessed,
                isDeleted: true,
            }),
        );
    };
}

export function deleteRhOwnership(rhoId, assetId, handleCloseCb) {
    return async function (dispatch) {
        const resp = await deleteRhOwnershipAPI(rhoId, assetId);

        handleCloseCb();
        dispatch(
            changeStateByNestedKey('assetMetadata', 'rhOwnershipSaveResult', {
                isSuccessful: true,
                hasWarning: !!resp?.data?.isAssetClaimedEventProcessed,
                isDeleted: true,
            }),
        );
    };
}
