import { useMemo, useState } from 'react';
import moment from 'moment';

export const useYearPicker = () => {
    const [activeField, setActiveField] = useState(null);

    const openYearPicker = (fieldName) => setActiveField(fieldName);
    const closeYearPicker = () => setActiveField(null);

    const handleYearChange = (date, setValue, trigger, fieldName) => {
        const year = moment(date).format('YYYY');
        setValue(fieldName, year);
        trigger(fieldName);
        closeYearPicker();
    };

    const isYearPickerOpen = useMemo(() => !!activeField, [activeField]);

    return {
        activeField,
        isYearPickerOpen,
        openYearPicker,
        closeYearPicker,
        handleYearChange,
    };
};
