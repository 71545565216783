const ASSET_METADATA_FIELDS = {
    assetTrackTitle: 'assetTrackTitle',
    assetTrackVersion: 'assetTrackVersion',
    assetIsrc: 'assetIsrc',
    assetFugaId: 'assetFugaId',
    assetNRPId: 'assetNrpId',
    assetDisplayArtists: 'assetDisplayArtists',
    numberOfFeaturedContr: 'numberOfFeaturedContr',
    numberOfNonFeaturedContr: 'numberOfNonFeaturedContr',
    lineUpComplete: 'lineUpComplete',
    pyear: 'pyear',
    pname: 'pname',
    duration: 'duration',
    contentType: 'contentType',
    recordingCountry: 'recordingCountry',
    commissioningCountry: 'commissioningCountry',
    recordingDate: 'recordingDate',
    recordingYear: 'recordingYear',
    isRemastered: 'isRemastered',
    medley: 'medley',
    containsSample: 'containsSample',
    genre: 'genre',
    language: 'language',
};

export { ASSET_METADATA_FIELDS };
