import {
    Button,
    ClickAwayListener,
    Input,
    List,
    ListItem,
    ListSubheader,
    makeStyles,
    Paper,
    Popover,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '@styling/muiThemes/FugaMainStyles';
import React, { useEffect, useState } from 'react';
import { getAllRecordLabels } from '@api/metadataController';

const useStyles = makeStyles(() => ({
    listItem: {
        paddingTop: '20px',
        paddingBottom: '18px',
    },
}));

export function RecordLabelAutocomplete({
    open,
    onClose,
    inputRef,
    anchorEl,
    setRecordLabel,
    setScppLabelId,
}) {
    const classes = useStyles();

    const [searchTerm, setSearchTerm] = useState('');
    const [allOptions, setAllOptions] = useState([]);
    const [options, setOptions] = useState([]);

    const loadRecordLabels = async () => {
        const resp = await getAllRecordLabels();

        setAllOptions(resp.data);
    };

    useEffect(() => {
        loadRecordLabels();
    }, []);

    useEffect(() => {
        setOptions(
            allOptions.filter(
                (item) => item.recordLabelName.includes(searchTerm) && item.recordLabelName !== '',
            ),
        );
    }, [searchTerm]);

    const setRecordLabelAndScppId = (recordLabel) => {
        setRecordLabel(recordLabel.recordLabelName);
        if (recordLabel?.scppId) {
            setScppLabelId(recordLabel.scppId);
        } else {
            setScppLabelId(null);
        }
        onClose();
    };

    function onSelectOption(item) {
        setRecordLabelAndScppId(item);
    }

    const handleAddNew = async () => {
        const selectedRecordLabel = { recordLabelName: searchTerm };
        setRecordLabelAndScppId(selectedRecordLabel);
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <ClickAwayListener onClickAway={onClose}>
                <Paper style={{ padding: '8px 0 8px 16px' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '334px',
                            height: '383px',
                        }}
                    >
                        <Input
                            placeholder="Search record labels"
                            inputProps={{ autoComplete: 'false' }}
                            inputRef={inputRef}
                            onChange={(evt) => setSearchTerm(evt.target.value)}
                            style={{ marginRight: '16px' }}
                        />
                        <List className="hideScroll sugesstions-list">
                            {options.length > 0 && (
                                <ListSubheader disableSticky> Suggested </ListSubheader>
                            )}
                            {options.length > 0 &&
                                options.map((item, index) => {
                                    return (
                                        <ListItem
                                            className={classes.listItem}
                                            button
                                            key={item.recordLabelName}
                                            value={item.recordLabelName}
                                            onClick={() => onSelectOption(item)}
                                        >
                                            {item.recordLabelName}
                                        </ListItem>
                                    );
                                })}
                        </List>
                    </div>
                    <div className="flex-horizontal">
                        <ThemeProvider theme={theme}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleAddNew}
                                disableElevation
                            >
                                ADD AS NEW
                            </Button>
                            <Button color="primary" onClick={onClose} disableElevation>
                                CANCEL
                            </Button>
                        </ThemeProvider>
                    </div>
                </Paper>
            </ClickAwayListener>
        </Popover>
    );
}
