import React, { useState, useEffect } from 'react';
import {
    Button,
    makeStyles,
    Popover,
    ClickAwayListener,
    Box,
    Typography,
    TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(() => ({
    actoinBtns: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
            marginLeft: '24px',
        },
        position: 'absolute',
        bottom: 0,
        left: 0,
        background: '#FFF',
        width: '100%',
        paddingBottom: '16px',
        paddingTop: '16px',
    },
    actionButton: {
        height: '26px',
    },
    searchInput: {
        width: '100%',
        padding: '22px 0 16px 0',
    },
    box: {
        padding: '16px',
        width: '334px',
        minHeight: '147px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
    },
    textStyle: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.25px',
    },
    itemsListContainer: {
        maxHeight: '463px',
        minHeight: '155px',
        overflow: 'auto',
        width: '318px',
        paddingBottom: '39px',
    },
    listItem: {
        padding: '13px 14px 14px 16px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            cursor: 'pointer',
        },
    },
    plusIcon: {
        margin: '0 7px 0 0',
        width: '18px',
        height: '18px',
    },
}));

function CommonLookupPopupWithSuggestionList({
    isOpen,
    closeCb,
    onConfirmCb,
    anchorEl,
    itemsList = [], // structure shold be [{ nameToBeSent: '...', displayName: '...', subName: ''}, { nameToBeSent: ....]
    setAnchorCb,
    displayOptions,
    selectedItemOptions,
    searchPlaceholder,
}) {
    const classes = useStyles();
    const [open, setOpen] = useState(isOpen);
    const [delayId, setDelayId] = useState('');
    const [localItemsList, setLocalItemsList] = useState([]);
    const [searchInputContent, setSearchInputContent] = useState('');

    useEffect(() => {
        setOpen(isOpen);
        if (isOpen) {
            if (displayOptions?.initialSuggestionsOn) setLocalItemsList(itemsList);
        }
    }, [isOpen]);

    useEffect(() => {
        if (!!selectedItemOptions?.selectedDataItem) {
            setSearchInputContent(selectedItemOptions?.selectedDataItem?.instrumentsAndRoles || '');
        }
    }, [selectedItemOptions?.selectedDataItem]);

    const handleClose = () => {
        setOpen(false);
        closeCb(false);
        setAnchorCb(null);
        if (selectedItemOptions?.setSelectedDataItem) selectedItemOptions.setSelectedDataItem(null);
    };

    const handleConfirm = () => {
        if (onConfirmCb) {
            onConfirmCb(
                {
                    nameToBeSent: searchInputContent,
                    displayName: searchInputContent,
                    addedAsNew: true,
                },
                {
                    isEditing: !!selectedItemOptions?.selectedDataItem,
                    selectedRowValue: selectedItemOptions?.selectedDataItem,
                },
            ); // pass selected value
        }
        handleClose();
    };

    const searchItem = (value) => {
        if (value == '') {
            setLocalItemsList([]);
        } else {
            const searchResults = itemsList.filter((item) => {
                return item.nameToBeSent.includes(value);
            });
            setLocalItemsList(searchResults);
        }
    };

    const handleSearchInputChange = ({ currentTarget }) => {
        const value = currentTarget.value;
        clearTimeout(delayId);

        setDelayId(
            setTimeout(() => {
                searchItem(value);
            }, 300),
        );
        setSearchInputContent(value);
    };

    const handleItemClick = (value) => {
        onConfirmCb(value, {
            isEditing: !!selectedItemOptions?.selectedDataItem,
            selectedRowValue: selectedItemOptions?.selectedDataItem,
        });
        handleClose();
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: displayOptions?.openUp ? 'top' : 'bottom',
                horizontal: displayOptions?.openUp ? 'center' : 'right',
            }}
            transformOrigin={{
                vertical: displayOptions?.openUp ? 'bottom' : 'top',
                horizontal: displayOptions?.openUp ? 'center' : 'right',
            }}
            className={classes.listItem}
        >
            <ClickAwayListener onClickAway={handleClose}>
                <Box className={`${classes.box}`}>
                    <TextField
                        placeholder={searchPlaceholder || 'Search Rights holders'}
                        className={classes.searchInput}
                        value={searchInputContent}
                        onChange={handleSearchInputChange}
                    ></TextField>
                    <div className={classes.itemsListContainer}>
                        {localItemsList.map((el, index) => {
                            return (
                                <div
                                    key={el.nameToBeSent + index}
                                    className={classes.listItem}
                                    onClick={() => handleItemClick(el)}
                                >
                                    <Typography variant="body1">{el.nameToBeSent}</Typography>
                                    {!displayOptions?.noSubName && (
                                        <Typography variant="body2">{el.subName}</Typography>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    {!displayOptions?.noOptionBtns && (
                        <div className={`${classes.actoinBtns}`}>
                            <Button
                                onClick={handleClose}
                                variant="text"
                                color="primary"
                                disableElevation
                                className={classes.actionButton}
                            >
                                cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                className={classes.actionButton}
                                onClick={handleConfirm}
                                disabled={localItemsList.length > 0 ? true : !searchInputContent}
                                style={{ marginRight: '8px' }}
                            >
                                <AddIcon className={classes.plusIcon} />
                                add as new
                            </Button>
                        </div>
                    )}
                </Box>
            </ClickAwayListener>
        </Popover>
    );
}

export default CommonLookupPopupWithSuggestionList;
